/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { handlePatchRequest } from "../../services/PatchTemplate";
import { handlePostRequest } from "../../services/PostTemplate";
import { Password } from "primereact/password";

const UpdatePassword = ({ onHide, userdataId }) => {
    const [showOtpField, setShowOtpField] = useState(false);
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [timer, setTimer] = useState(null);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [emailLoadingBtn, setEmailLoadingBtn] = useState(false);
    const [otpLoadingBtn, setOtpLoadingBtn] = useState(false);
    const [emailValidation, setEmailvalidation] = useState("");
    const [otpValidation, setOtpvalidation] = useState("");
    const [disableEmail, setDisableEmail] = useState(false);
    const [disableOtp, setDisableOtp] = useState(false);

    const inputRefs = useRef([]);
    const dispatch = useDispatch();

    const userEmail = JSON.parse(localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails"));

    const validationSchema = Yup.object().shape({
        password: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters long"),
        confirmPassword: Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: userEmail?.data?.email || "",
            password: "",
            confirmPassword: "",
            otp: "",
        },

        onSubmit: async (data) => {
            setLoadingBtn(true);

            let newData = {
                userId: userdataId,
                password: data?.password ? data?.password : undefined,
            };

            const res = await dispatch(handlePatchRequest(newData, `/api/v1/users/update`, false, true));
            if (res.status === 200) {
                onHide();
            }
            setLoadingBtn(false);
        },
    });

    const verifyEmail = async () => {
        if (!formik.values.email) {
            setEmailvalidation("This field is required");
        } else {
            setEmailLoadingBtn(true);
            const requestObj = {
                email: formik?.values?.email,
            };

            const response = await dispatch(handlePostRequest(requestObj, "/api/v1/users/sendOtp", false, true));
            if (response?.status === 200) {
                setShowOtpField(true);
                setTimer(120);
                setDisableEmail(true);
            }
            setEmailLoadingBtn(false);
        }
    };

    const verifyOtp = async () => {
        if (!formik.values.otp) {
            setOtpvalidation("OTP is required");
        } else {
            setOtpLoadingBtn(true);
            const requestObj = {
                email: formik?.values?.email,
                otp: parseInt(formik?.values?.otp),
            };
            const response = await dispatch(handlePostRequest(requestObj, "/api/v1/users/verifyOtp", false, true));
            if (response?.status === 200) {
                setShowPasswordField(true);
                setDisableOtp(true);
                setTimer(null);
            } else {
                formik.setFieldValue("otp", "");
            }
            setOtpLoadingBtn(false);
        }
    };

    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [timer]);

    const handleInputChange = (index, e) => {
        const { value } = e.target;
        if (/^[0-9]$/.test(value)) {
            const newOtp = [...formik.values.otp];
            newOtp[index] = value;
            formik.setFieldValue("otp", newOtp.join(""));
            if (index < inputRefs.current.length - 1 && value) {
                inputRefs.current[index + 1].focus();
            }
        } else if (value === "") {
            const newOtp = [...formik.values.otp];
            newOtp[index] = value;
            formik.setFieldValue("otp", newOtp.join(""));
        }
    };

    const handleResendOtp = async () => {
        const data = { email: formik.values.email };
        const result = await dispatch(handlePostRequest(data, "/api/v1/users/sendOtp", false, true));
        if (result.status === 200) {
            setTimer(120);
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <form className="p-fluid p-mt-2" onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="p-field col-12 md:col-6 ">
                        <div className="field mt-3">
                            <label>Email</label> <span className="Label__Required">*</span>
                            <InputText
                                id="email"
                                placeholder="Enter Email"
                                name="email"
                                value={formik?.values?.email}
                                maxLength={30}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setEmailvalidation("");
                                }}
                                className={`Input__Round ${emailValidation ? "p-invalid" : ""}`}
                                disabled
                            />
                            <small className="p-error">{emailValidation}</small>
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6 ">
                        <div className="field mt-6">
                            <Button className="w-auto border-radius-12px" label="Send OTP" type="button" onClick={verifyEmail} loading={emailLoadingBtn} style={{ width: "30px" }} disabled={disableEmail} />
                        </div>
                    </div>
                </div>
                {showOtpField && (
                    <>
                        {" "}
                        <div className="login-div">
                            <label htmlFor="number" className="block font-medium mb-2">
                                Enter OTP
                            </label>
                            <div className="otp-container">
                                {[...Array(4)].map((_, index) => (
                                    <input
                                        key={index}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        type="text"
                                        maxLength="1"
                                        className="otp-box"
                                        value={formik.values.otp[index] || ""}
                                        onChange={(e) => {
                                            handleInputChange(index, e);
                                            setOtpvalidation("");
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Backspace" && !formik.values.otp[index] && index > 0) {
                                                inputRefs.current[index - 1].focus();
                                            }
                                        }}
                                        disabled={disableOtp}
                                    />
                                ))}
                            </div>
                            <div className="flex justify-content-center mt-2">
                                <small className="p-error">{otpValidation}</small>
                            </div>
                            <div className="flex justify-content-center updatePasswordButtons mt-4">
                                <Button className="Login-Button" label="Verify" type="button" onClick={verifyOtp} loading={otpLoadingBtn} disabled={disableOtp} />
                                <Button className="Resend-Button ml-3" label={timer > 0 ? `Resend OTP (${Math.floor(timer / 60)}m ${timer % 60}s)` : "Resend OTP"} onClick={handleResendOtp} disabled={disableOtp || timer > 0} />
                            </div>
                        </div>
                    </>
                )}
                {showPasswordField && (
                    <div className="p-fluid formgrid grid pl-2 pr-2">
                        <div className="p-field col-12 md:col-6">
                            <div className="field mt-4">
                                <label htmlFor="password" className="Label__Text">
                                    Password
                                </label>
                                <Password
                                    keyfilter={/[^\s]/}
                                    placeholder="Enter your password"
                                    id="password"
                                    type="password"
                                    autoComplete="off"
                                    name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    className="w-12 p-inputtext-login mb-2 "
                                    maxLength={15}
                                    feedback={false}
                                    autocomplete="off"
                                    toggleMask
                                />

                                {formik.errors.password && formik.touched.password && <div style={{ color: "red" }}>{formik.errors.password}</div>}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-6">
                            <div className="field mt-4">
                                <label htmlFor="confirmPassword" className="Label__Text">
                                    Confirm Password
                                </label>
                                <Password
                                    keyfilter={/[^\s]/}
                                    placeholder="Re-enter your password"
                                    id="confirmPassword"
                                    type="password"
                                    autoComplete="off"
                                    name="confirmPassword"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    className="w-12 p-inputtext-login mb-2 "
                                    maxLength={15}
                                    feedback={false}
                                    autocomplete="off"
                                    toggleMask
                                />

                                {formik.errors.confirmPassword && formik.touched.confirmPassword && <div style={{ color: "red" }}>{formik.errors.confirmPassword}</div>}
                            </div>
                        </div>
                    </div>
                )}

                <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                    <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                    {showPasswordField && <Button className="Save-Button ml-2" label="Update" type="submit" loading={loadingBtn} />}
                </div>
            </form>
        </>
    );
};

export default UpdatePassword;
