import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { handlePostRequest } from "../../../../services/PostTemplate";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import classNames from "classnames";

const AddTransmission = ({ onHide, getTransmissionList }) => {
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        transmission: Yup.string().required("This field is required").min(3, "field must be at least 3 characters long").max(50, "field cannot exceed 50 characters"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            transmission: "",
            isActive: true,
        },

        onSubmit: async (data) => {
            let obj = {
                transmission: data?.transmission,
                isActive: data?.isActive,
            };
            const response = await dispatch(handlePostRequest(obj, "/api/v1/transmission/create", false, true));
            if (response?.status === 200) {
                getTransmissionList();
                onHide();
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="field col-12 md:col-6">
                        <label> Transmission </label>
                        <span className="Label__Required">*</span>
                        <InputText placeholder="Enter Transmission Name" className={classNames({ "p-invalid": isFormFieldValid("transmission") }, "Input__Round")} id="transmission" name="transmission" maxLength={30} type="text" value={formik.values.transmission} onChange={formik.handleChange} />
                        {getFormErrorMessage("transmission")}
                    </div>

                    <div className="flex flex-wrap gap-3 ">
                        <div className="flex align-items-center ml-3">
                            <RadioButton
                                inputId="active"
                                value={true}
                                onChange={(e) => {
                                    formik.setFieldValue("isActive", e.value);
                                }}
                                checked={formik.values.isActive === true}
                            />
                            <label htmlFor="isActive" className="ml-2">
                                Active
                            </label>
                        </div>
                        <div className="flex align-items-center ml-5">
                            <RadioButton
                                inputId="inactive"
                                value={false}
                                onChange={(e) => {
                                    formik.setFieldValue("isActive", e.value);
                                }}
                                checked={formik.values.isActive === false}
                            />
                            <label htmlFor="isActive" className="ml-2">
                                Inactive
                            </label>
                        </div>
                    </div>

                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        <Button className="Save-Button ml-2" label="Add" type="submit" />
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddTransmission;
