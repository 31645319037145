import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import "./AppTopbar.scss";
import { Badge } from "primereact/badge";
import logo from "../src/assets/png/finallogo.png";

export const AppTopbar = (props) => {
    const op = useRef(null);
    const history = useHistory();
    const firstName = sessionStorage.getItem("firstName");
    const lastName = sessionStorage.getItem("lastName");

    // const userName = sessionStorage.getItem("user");
    // const items = [
    //     // {
    //     //     label: "Reset Password",
    //     //     command: () => {
    //     //         history.push("/resetpassword");
    //     //     },
    //     // },

    //     {
    //         label: "Logout",
    //         command: () => {
    //             window.sessionStorage.clear();
    //             history.push("/");
    //         },
    //     },
    // ];

    return (
        <>
            <div className="layout-topbar">
                {/* <button type="button" className="p-link  layout-menu-button layout-topbar-button ml-1" onClick={props.onToggleMenuClick}>
                    <i className="pi pi-bars" />
                </button>
                <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                    <i className="pi pi-ellipsis-v" />
                </button> */}

                <Link to="/" className="layout-topbar-logo">
                    <img src={logo} alt="car 2 parts" style={{ height: "50px", marginLeft: "100%" }} />

                    {/* <div className="flex flex-column ml-3 text-center topbar-heading">
                        <span style={{ fontWeight: "bold", fontSize: "22px" }}>Auto Car</span>
                    </div> */}
                </Link>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                    {/* <li>
                        <button className="p-link layout-topbar-button mx-4" onClick={props.onMobileSubTopbarMenuClick}>
                            <label htmlFor="" className="font-semibold">
                                {userName}
                            </label>
                        </button>
                    </li> */}

                    <li className="flex">
                        {firstName ? (
                            <div className="">
                                <h2 className="">
                                    <Badge value={`${firstName} ${lastName}`} severity="warning" style={{ fontSize: "0.89rem" }} />
                                </h2>
                            </div>
                        ) : null}

                        {/* <SplitButton model={items} className="p-button-text custom-button-css ml-4"></SplitButton> */}
                        {/* <img src={profile} alt="auto-car" style={{ width: "35px" }} /> */}
                    </li>
                </ul>
            </div>
        </>
    );
};
