import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { handlePutRequest } from "../../../../services/PutTemplate";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../../../services/GetTemplate";
import Loading from "../../../../components/Loader";
import classNames from "classnames";

const EditViewDimension = ({ onHide, getDimensionList, dimensiondataId, isEditable }) => {
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const dispatch = useDispatch();

    const getDimensionById = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest(`/api/v1/dimension/byId/${dimensiondataId}`, false));
        if (response?.status === 200) {
            const keyData = response?.data;
            formik.setFieldValue("dimension", keyData?.dimension);
        }
        setLoading(false);
    };
    useEffect(() => {
        getDimensionById();
    }, [dimensiondataId]);

    const validationSchema = Yup.object().shape({
        dimension: Yup.string()
            ?.matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .required("This field is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            dimension: "",
        },

        onSubmit: async (data) => {
            setLoadingBtn(true);

            let obj = {
                dimension: data?.dimension,
            };
            const response = await dispatch(handlePutRequest(obj, `/api/v1/dimension/update/${dimensiondataId}`, false, true));
            if (response?.status === 200) {
                getDimensionList();
                onHide();
            }
            setLoadingBtn(false);
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            {loading ? <Loading /> : <></>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="field col-12 md:col-6">
                        <label> Dimension </label>
                        <span className="Label__Required">*</span>
                        <InputText placeholder="Enter Dimension" className={classNames({ "p-invalid": isFormFieldValid("dimension") }, "Input__Round")} id="dimension" name="dimension" type="text" maxLength={30} value={formik.values.dimension} onChange={formik.handleChange} disabled={!isEditable} />
                        {getFormErrorMessage("dimension")}
                    </div>

                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        {isEditable === true && <Button className="Save-Button ml-2" label="Update" type="submit" loading={loadingBtn} />}
                    </div>
                </div>
            </form>
        </>
    );
};

export default EditViewDimension;
