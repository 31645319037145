import React, { createContext, useEffect, useState } from "react";

// Create the context
export const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({ name: "", role: "", image: "" });

    useEffect(() => {
        if (localStorage.getItem("rememberMe") === "y") {
            const session = JSON.parse(localStorage.getItem("userDetails"));
            setUser({ name: session?.data?.name, role: session?.data?.role, image: session?.data?.profileImage });
        } else {
            const session = JSON.parse(sessionStorage.getItem("userDetails"));
            setUser({ name: session?.data?.name, role: session?.data?.role, image: session?.data?.profileImage });
        }
    }, [sessionStorage.getItem("userDetails"), localStorage.getItem("userDetails")]);

    return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};
