import React from "react";

export const AppFooter = (props) => {
    return (
        <></>
        // <div className="layout-footer bg-gray-400 " style={{ height: "20px", display: "flex", alignItems: "end" }}>
        //     <p className="text-900 pb-2 font-semibold" style={{ height: "20px", display: "flex", justifyContent: "center" }}>
        //         copyrights @ car2parts.com
        //     </p>
        // </div>
    );
};
