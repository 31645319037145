import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import loginImage from "../../assets/png/splash.png";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { handlePostRequest } from "../../services/PostTemplate";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ForgotPassword = () => {
    const [loadingBtn, setLoadingBtn] = useState(false);

    const history = useHistory();

    const dispatch = useDispatch();

    const userEmail = sessionStorage.getItem("credentials");
    const initialEmail = (userEmail && userEmail) || "";
    const validationSchema = Yup.object().shape({ email: Yup.string().required("This field is required").email("Invalid email format") });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: initialEmail,
        },
        onSubmit: async (data) => {
            setLoadingBtn(true);

            const result = await dispatch(handlePostRequest(data, "/api/v1/users/sendOtp", false, true));
            if (result?.status === 200) {
                sessionStorage.setItem("credentials", data?.email);
                history.push({
                    pathname: "/otpverification",
                });
            }
            setLoadingBtn(false);
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="loginBgContainer 1">
                <img src={loginImage} alt="Image Alt Text" className="loginBgImage" />

                <div className="left-section">
                    <form onSubmit={formik.handleSubmit} className="login-form">
                        <i
                            class="pi pi-arrow-circle-left"
                            style={{ fontSize: "2em", cursor: "pointer" }}
                            onClick={() => {
                                history.goBack();
                            }}
                        ></i>
                        <h2 className="mb-4" style={{ color: "white", letterSpacing: "1px", fontWeight: "700", textAlign: "center" }}>
                            Forgot Password
                        </h2>
                        <p style={{ fontWeight: "250", fontSize: "12px", color: "" }}>Don't worry it happen's to all of us. Enter your email below to recover your password.</p>
                        <div className="login-div mt-6">
                            <label htmlFor="email1" className="block text-xl font-medium mb-2">
                                Email<span style={{ color: "red" }}>*</span>
                            </label>
                            <InputText keyfilter={/[^\s]/} placeholder="Enter your email" id="email" name="email" type="email" autoComplete="off" value={formik.values.email} onChange={formik.handleChange} className="w-12 p-inputtext-login" />
                            {getFormErrorMessage("email")}
                            <div className="flex justify-content-center mt-3 text-sm">
                                <Link to="*">
                                    <p style={{ color: "orange" }}>Already have an account? Login</p>
                                </Link>
                            </div>

                            <div className="col-12 d-flex  text-center mt-5">
                                <Button className="Login-Button" label="Submit" type="submit" loading={loadingBtn} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
