/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import * as Yup from "yup";
import classNames from "classnames";
import { useFormik } from "formik";
import { handlePostRequest } from "../../services/PostTemplate";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../services/GetTemplate";
import { Dialog } from "primereact/dialog";
import Avatar from "react-avatar-edit";
// import profilepng from "../../assets/png/dummyimage.png";
import profilepng from "../../assets/png/dummyprofilepicture.png";

const AddUser = ({ onHide, getUserList }) => {
    const [allRoles, setAllRoles] = useState([]);
    const [logo, setLogo] = useState(null);
    const [imageCrop, setImageCrop] = useState(false);
    const [profile, setProfile] = useState([]);
    const [pview, setPview] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const userinfo = JSON.parse(localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails"));

    const profileFinal = profile.map((item) => item.pview);

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .required("This field is required")
            .matches(/^[a-zA-Z0-9\s]*$/, "Name must be alpha numeric"),
        phone: Yup.string()
            .matches(/^\d{9,11}$/, "Length must be between 9 and 11 digits")
            .required("This field is required"),

        role: Yup.string().required("This field is required"),
        email: Yup.string().required("This field is required").email("Invalid email format"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            phone: "",
            role: "",
            email: "",
        },

        onSubmit: async () => {
            setLoadingBtn(true);

            let data = {
                name: formik.values.name,
                phone: formik.values.phone,
                role: formik.values.role,
                email: formik.values.email,
                profileImage: logo ? logo : "",
            };

            const response = await dispatch(handlePostRequest(data, `/api/v1/auth/SignUp`, false, true));
            if (response.status === 200) {
                onHide();
                getUserList();
            }
            setLoadingBtn(false);
        },
    });

    const getAllRoles = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/roles/getAll", false));
        if (response) {
            const filteredRoles = response?.data?.filter((item) => {
                if (userinfo?.data?.role.toLowerCase() === "admin") {
                    return item?.name.toLowerCase() !== "super admin";
                }
                return true;
            });
            setAllRoles(filteredRoles);
        }
    };

    useEffect(() => {
        getAllRoles();
    }, []);

    const onClose = () => {
        setPview(null);
    };
    const onCrop = (view) => {
        setPview(view);
    };
    const saveCropImage = () => {
        setProfile([...profile, { pview }]);
        setImageCrop(false);
        setLogo(pview);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <form className="p-fluid p-mt-2" onSubmit={formik.handleSubmit}>
                <div className="text-center mb-2">
                    <div className="flex flex-column justify-content-center align-items-center">
                        <img style={{ width: "150px", height: "150px", borderRadius: "50%", objectFit: "cover", border: "4px solid black" }} src={profileFinal.length ? profileFinal : profilepng} alt="" />
                        <Button onClick={() => setImageCrop(true)} type="button" label="Upload Image" className="Save-Button-Profile mt-3" />
                        <Dialog visible={imageCrop} onHide={() => setImageCrop(false)}>
                            <div>
                                <Avatar width={500} height={400} onCrop={onCrop} onClose={onClose} shadingColor={"#474649"} backgroundColor={"474649"} />
                                <div className="flex flex-column align-items-center w-12 mt-5">
                                    <div className="flex justify-content-around w-12 mt-4">
                                        <Button onClick={saveCropImage} label="Save" icon="pi pi-check" type="button" className="Save-Button" />
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="p-field col-12 md:col-6">
                        <div className="field mt-3">
                            <label>Name</label>
                            <span className="Label__Required">*</span>
                            <InputText id="name" placeholder="Enter Name" name="name" value={formik?.values?.name} maxLength={30} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__Round")} />
                            {getFormErrorMessage("name")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6">
                        <div className="field mt-3">
                            <label>Phone No</label>
                            <span className="Label__Required">*</span>

                            <InputText keyfilter="int" id="phone" placeholder="Enter Phone No" name="phone" value={formik?.values?.phone} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("phone") }, "Input__Round")} />
                            {getFormErrorMessage("phone")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6">
                        <div className="field mt-4">
                            <label htmlFor="role" className={classNames({ "p-error": isFormFieldValid("role") }, "Label__Text")}>
                                Role
                            </label>
                            <span className="Label__Required">*</span>

                            <Dropdown
                                id="role"
                                placeholder="Select Role"
                                options={allRoles}
                                optionLabel="name"
                                name="role"
                                optionValue="name"
                                filter
                                showClear={!!formik?.values?.role}
                                filterBy="name"
                                showFilterClear={true}
                                resetFilterOnHide={true}
                                onChange={(e) => {
                                    formik?.setFieldValue("role", e.target.value ? e.target.value : "");
                                }}
                                value={formik.values.role || ""}
                                className={classNames({ "p-invalid": isFormFieldValid("role") }, "Dropdown__Round")}
                            />
                            {getFormErrorMessage("role")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6">
                        <div className="field mt-4">
                            <label htmlFor="email" className="Label__Text">
                                Email
                            </label>
                            <span className="Label__Required">*</span>

                            <InputText id="email" name="email" placeholder="Enter Email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("email") }, "Input__Round")} type="text" />
                            {getFormErrorMessage("email")}
                        </div>
                    </div>

                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        <Button className="Save-Button ml-2" label="Add" type="submit" loading={loadingBtn} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddUser;
