import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { handlePutRequest } from "../../../../services/PutTemplate";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../../../services/GetTemplate";
import Loading from "../../../../components/Loader";
import classNames from "classnames";

const EditViewTransmission = ({ onHide, getTransmissionList, transmissiondataId, isEditable }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const getTansmissionById = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest(`/api/v1/transmission/getById/${transmissiondataId}`, false));
        if (response?.status === 200) {
            const keyData = response?.data;
            formik.setFieldValue("transmission", keyData?.transmission);
            formik.setFieldValue("isActive", keyData?.isActive);
        }
        setLoading(false);
    };

    useEffect(() => {
        getTansmissionById();
    }, [transmissiondataId]);

    const validationSchema = Yup.object().shape({
        transmission: Yup.string().required("This field is required").min(3, "field must be at least 3 characters long").max(50, "field cannot exceed 50 characters"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            transmission: "",
            isActive: "",
        },

        onSubmit: async (data) => {
            let obj = {
                transmission: data?.transmission,
                id: transmissiondataId,
                isActive: data?.isActive,
            };
            const response = await dispatch(handlePutRequest(obj, `/api/v1/transmission/update/`, false, true));
            if (response?.status === 200) {
                getTransmissionList();
                onHide();
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            {loading ? <Loading /> : <></>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="field col-12 md:col-6">
                        <label> Transmission </label>
                        <span className="Label__Required">*</span>
                        <InputText
                            placeholder="Enter Transmission Name"
                            className={classNames({ "p-invalid": isFormFieldValid("transmission") }, "Input__Round")}
                            id="transmission"
                            name="transmission"
                            type="text"
                            maxLength={30}
                            value={formik.values.transmission}
                            onChange={formik.handleChange}
                            disabled={!isEditable}
                        />
                        {getFormErrorMessage("transmission")}
                    </div>

                    <div className="flex flex-wrap gap-3 ">
                        <div className="flex align-items-center ml-3">
                            <RadioButton
                                inputId="active"
                                value={true}
                                disabled={!isEditable}
                                onChange={(e) => {
                                    formik.setFieldValue("isActive", e.value);
                                }}
                                checked={formik.values.isActive === true}
                            />
                            <label htmlFor="isActive" className="ml-2">
                                Active
                            </label>
                        </div>
                        <div className="flex align-items-center ml-5">
                            <RadioButton
                                inputId="inactive"
                                value={false}
                                disabled={!isEditable}
                                onChange={(e) => {
                                    formik.setFieldValue("isActive", e.value);
                                }}
                                checked={formik.values.isActive === false}
                            />
                            <label htmlFor="isActive" className="ml-2">
                                Inactive
                            </label>
                        </div>
                    </div>
                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        {isEditable === true && <Button className="Save-Button ml-2" label="Update" type="submit" />}
                    </div>
                </div>
            </form>
        </>
    );
};

export default EditViewTransmission;
