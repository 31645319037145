import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { handlePostRequest } from "../../../../services/PostTemplate";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import classNames from "classnames";

const AddDimensions = ({ onHide, getDimensionList }) => {
    const [loadingBtn, setLoadingBtn] = useState(false);

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        dimension: Yup.string()
            ?.matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .required("This field is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            dimension: "",
        },

        onSubmit: async (data) => {
            setLoadingBtn(true);

            let obj = {
                dimension: data?.dimension,
            };
            const response = await dispatch(handlePostRequest(obj, "/api/v1/dimension/create", false, true));
            if (response?.status === 200) {
                getDimensionList();
                onHide();
            }
            setLoadingBtn(false);
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="field col-12 md:col-6">
                        <label> Dimension </label>
                        <span className="Label__Required">*</span>
                        <InputText placeholder="Enter Dimension" className={classNames({ "p-invalid": isFormFieldValid("dimension") }, "Input__Round")} id="dimension" name="dimension" type="text" maxLength={30} value={formik.values.dimension} onChange={formik.handleChange} />
                        {getFormErrorMessage("dimension")}
                    </div>

                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        <Button className="Save-Button ml-2" label="Add" type="submit" loading={loadingBtn} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddDimensions;
