import React from "react";
import pagenotfound from "../../assets/png/pagenotfound.png";
const NotFound = () => {
    return (
        <div className="text">
            <img src={pagenotfound} alt="img txt" className="pageNotFound" />
        </div>
    );
};

export default NotFound;
