import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, useLocation, Switch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { AppTopbar } from "./AppTopbar";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import { useHistory } from "react-router-dom";
import Login from "./pages/login/Login";
import ManageUsers from "./pages/UserManagement/ManageUsers";
import ForgotPassword from "./pages/ForgetPassword/ForgotPassword";
import Dashboard from "./pages/Dashboard/dashboard";
import QueryManagement from "./pages/QueryManagement/Pages/QueryManagement";
import ViewQuery from "./pages/QueryManagement/Components/ViewQuery";
import InventoryCart from "./pages/InventoryCart/Pages/InventoryCart";
import ManageSystemSetting from "./pages/SystemSetting/ManageSystemSetting";
import ManageCampany from "./pages/SystemSetting/Pages/CampanyManagement/ManageCampany";
import ManageCatagory from "./pages/SystemSetting/Pages/CategoryManagement/ManageCategory";
import ManageCarModel from "./pages/SystemSetting/Pages/CarModelManagement/ManageCarModel";
import ManageCar from "./pages/Cars/CarManagement";
import ManageVariant from "./pages/SystemSetting/Pages/VariantManagement/ManageVariant";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ManagePart from "./pages/Parts/ManagementParts";
import OtpVerification from "./pages/Otp/OtpVerification";
import ManageTransmission from "./pages/SystemSetting/Pages/TransmissionManagemenr/ManageTransmission";
import ManagePowerType from "./pages/SystemSetting/Pages/PowerTypeManagement/ManagePowerType";
import ManageYear from "./pages/SystemSetting/Pages/YearManagement/ManageYear";
import ManageBanner from "./pages/SystemSetting/Pages/BannerManagement/ManageBanner";
import AddUser from "./pages/UserManagement/AddUser";
import ManageRequest from "./pages/Request/ManageRequest";
import ManageDimension from "./pages/SystemSetting/Pages/Dimension/ManageDimension";
import NotFound from "./pages/NotFound/NotFound";
import InventoryLogs from "./pages/InventoryCart/Components/InventoryLogs";
import ManageFeature from "./pages/SystemSetting/Pages/FeaturesManagement/ManageFeatures";
import UserProfile from "./pages/UserManagement/UserProfile";
import { AppFooter } from "./AppFooter";
import { UserProvider } from "./components/UserContext";
import ProfileData from "./components/ProfileData";
import Modal from "./components/modal/Modal";
import { handleGetRequest } from "./services/GetTemplate";
import { useDispatch } from "react-redux";

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [userRole, setUserRole] = useState("");
    const [logoutModal, setLogoutModal] = useState(false);
    const [QuotationsCount, setQuotationsCount] = useState([]);

    const copyTooltipRef = useRef();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem("rememberMe") === "y") {
            setUserRole(JSON.parse(localStorage.getItem("userDetails")));
        } else {
            setUserRole(JSON.parse(sessionStorage.getItem("userDetails")));
        }
    }, [sessionStorage.getItem("userDetails"), localStorage.getItem("userDetails")]);

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;
        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }
        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const adminMenu = [
        {
            items: [
                {
                    label: "Dashboard",
                    icon: "pi pi-book",
                    to: "/",
                },
                {
                    label: "User Management",
                    icon: "pi pi-users",
                    to: "/usermanagement",
                },
                {
                    label: "Parts Management",
                    icon: "pi pi-plus",
                    to: "/managepart",
                },
                {
                    label: "Variants Management",
                    icon: "pi pi-car",
                    to: "/managevariant",
                },

                {
                    label: "Inventory Cart",
                    icon: "pi pi-list",
                    to: "/inventorycart",
                },

                {
                    label: "System Settings",
                    icon: "pi pi-cog",
                    to: "/systemsettings",
                },

                {
                    label: `Query Management`,
                    icon: "pi pi-book",
                    to: "/querymanagement",
                    count: QuotationsCount || 0,
                },
            ],
        },
    ];
    const userMenu = [
        {
            items: [
                {
                    label: "Dashboard",
                    icon: "pi pi-book",
                    to: "/",
                },
                {
                    label: "Parts Management",
                    icon: "pi pi-plus",
                    to: "/managepart",
                },
                {
                    label: "Variants Management",
                    icon: "pi pi-car",
                    to: "/managevariant",
                },

                {
                    label: "Inventory Cart",
                    icon: "pi pi-list",
                    to: "/inventorycart",
                },

                {
                    label: "System Settings",
                    icon: "pi pi-cog",
                    to: "/systemsettings",
                },

                {
                    label: "Query Management",
                    icon: "pi pi-book",
                    to: "/querymanagement",
                    count: QuotationsCount || 0,
                },
            ],
        },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    LoadingOverlay.propTypes = undefined;

    const handleLogOut = () => {
        sessionStorage.clear();
        localStorage.clear();
        history.push("/");
    };
    const handleProfileClick = () => {
        history.push("/userprofile");
    };

    const handleLogoutModalClose = () => {
        setLogoutModal(false);
    };
    const handleLogoutModal = () => {
        handleLogOut();
        setLogoutModal(false);
    };

    const getQuotationsList = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/qoutation/getAll", false));
        if (response) {
            setQuotationsCount(response?.data?.totalCount);
        }
    };

    useEffect(() => {
        if (userRole?.data?.accessToken) {
            getQuotationsList();
        }
    }, [userRole]);

    const rememberMe = localStorage.getItem("rememberMe");
    const userDetails = rememberMe === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails");

    return (
        <UserProvider>
            <Modal isOpen={logoutModal} onClose={handleLogoutModalClose} onConfirm={handleLogoutModal} type="logout" headerText="Are you sure you want to Logout" />

            <div className={wrapperClass} onClick={onWrapperClick}>
                <ToastContainer />
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
                {userDetails ? (
                    <>
                        <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <div className="sidebar-items" style={{ size: "25px", textAlign: "center" }}>
                                <ProfileData />
                                <div>
                                    <button
                                        className="p-link layout-topbar-button user-image mr-3"
                                        style={{ color: "white" }}
                                        type="button"
                                        onClick={() => {
                                            handleProfileClick();
                                        }}
                                    >
                                        <i className="pi pi-user white" title="Profile"></i>
                                    </button>
                                    <button
                                        className="p-link layout-topbar-button user-image"
                                        style={{ color: "white" }}
                                        type="button"
                                        onClick={() => {
                                            setLogoutModal(true);
                                        }}
                                    >
                                        <i className="pi pi-power-off white" title="Logout"></i>
                                    </button>
                                </div>
                            </div>

                            <AppMenu model={userRole?.data?.role.toLowerCase().includes("user") ? userMenu : adminMenu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                        </div>
                        <div className="layout-main-container">
                            <div className="layout-main">
                                <Switch>
                                    <Route path="/" exact component={Dashboard} />
                                    <Route path="/usermanagement" component={ManageUsers} />
                                    <Route path="/userprofile" component={UserProfile} />
                                    <Route path="/adduser" component={AddUser} />
                                    <Route path="/querymanagement" component={QueryManagement} />
                                    <Route path="/viewquery/:id" component={ViewQuery} />
                                    <Route path="/inventorycart" component={InventoryCart} />
                                    <Route path="/inventorylogs/:id" component={InventoryLogs} />
                                    <Route path="/systemsettings" component={ManageSystemSetting} />
                                    <Route path="/managecompany" component={ManageCampany} />
                                    <Route path="/managecategory" component={ManageCatagory} />
                                    <Route path="/managefeature" component={ManageFeature} />
                                    <Route path="/managecarmodel" component={ManageCarModel} />
                                    <Route path="/managecar" component={ManageCar} />
                                    <Route path="/managevariant" component={ManageVariant} />
                                    <Route path="/managepart" component={ManagePart} />
                                    <Route path="/managetransmission" component={ManageTransmission} />
                                    <Route path="/managepowertype" component={ManagePowerType} />
                                    <Route path="/manageyear" component={ManageYear} />
                                    <Route path="/managebanner" component={ManageBanner} />
                                    <Route path="/managerequest" component={ManageRequest} />
                                    <Route path="/managedimension" component={ManageDimension} />
                                    <Route path="*" component={NotFound} />
                                </Switch>
                            </div>
                        </div>
                    </>
                ) : (
                    <Switch>
                        <Route path="/forgotpassword" exact component={ForgotPassword} />
                        <Route path="/otpverification" exact component={OtpVerification} />
                        <Route path="/resetpassword" exact component={ResetPassword} />
                        <Route path="*" exact component={Login} />
                    </Switch>
                )}
                {/* {localStorage.getItem("rememberMe") && localStorage.getItem("rememberMe") === "y" ? (
                    localStorage.getItem("userDetails") ? (
                        <>
                            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                            <div className="layout-sidebar" onClick={onSidebarClick}>
                                <div className="sidebar-items" style={{ size: "25px", textAlign: "center" }}>
                                    <ProfileData />
                                    <div>
                                        <button
                                            className="p-link layout-topbar-button user-image mr-3"
                                            style={{ color: "white" }}
                                            type="button"
                                            onClick={() => {
                                                handleProfileClick();
                                            }}
                                        >
                                            <i className="pi pi-user white" title="Profile"></i>
                                        </button>
                                        <button
                                            className="p-link layout-topbar-button user-image"
                                            style={{ color: "white" }}
                                            type="button"
                                            onClick={() => {
                                                setLogoutModal(true);
                                            }}
                                        >
                                            <i className="pi pi-power-off white" title="Logout"></i>
                                        </button>
                                    </div>
                                </div>

                                <AppMenu model={userRole?.data?.role.toLowerCase().includes("user") ? userMenu : adminMenu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                            </div>
                            <div className="layout-main-container">
                                <div className="layout-main">
                                    <Switch>
                                        <Route path="/" exact component={Dashboard} />
                                        <Route path="/usermanagement" component={ManageUsers} />
                                        <Route path="/userprofile" component={UserProfile} />
                                        <Route path="/adduser" component={AddUser} />
                                        <Route path="/querymanagement" component={QueryManagement} />
                                        <Route path="/viewquery/:id" component={ViewQuery} />
                                        <Route path="/inventorycart" component={InventoryCart} />
                                        <Route path="/inventorylogs/:id" component={InventoryLogs} />
                                        <Route path="/systemsettings" component={ManageSystemSetting} />
                                        <Route path="/managecompany" component={ManageCampany} />
                                        <Route path="/managecategory" component={ManageCatagory} />
                                        <Route path="/managefeature" component={ManageFeature} />
                                        <Route path="/managecarmodel" component={ManageCarModel} />
                                        <Route path="/managecar" component={ManageCar} />
                                        <Route path="/managevariant" component={ManageVariant} />
                                        <Route path="/managepart" component={ManagePart} />
                                        <Route path="/managetransmission" component={ManageTransmission} />
                                        <Route path="/managepowertype" component={ManagePowerType} />
                                        <Route path="/manageyear" component={ManageYear} />
                                        <Route path="/managebanner" component={ManageBanner} />
                                        <Route path="/managerequest" component={ManageRequest} />
                                        <Route path="/managedimension" component={ManageDimension} />
                                        <Route path="*" component={NotFound} />
                                    </Switch>
                                </div>
                            </div>
                        </>
                    ) : (
                        <Switch>
                            <Route path="/forgotpassword" exact component={ForgotPassword} />
                            <Route path="/otpverification" exact component={OtpVerification} />
                            <Route path="/resetpassword" exact component={ResetPassword} />
                            <Route path="*" exact component={Login} />
                        </Switch>
                    )
                ) : sessionStorage.getItem("userDetails") ? (
                    <>
                        <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <div className="sidebar-items" style={{ size: "25px", textAlign: "center" }}>
                                <ProfileData />
                                <div>
                                    <button
                                        className="p-link layout-topbar-button user-image mr-3"
                                        style={{ color: "white" }}
                                        type="button"
                                        onClick={() => {
                                            handleProfileClick();
                                        }}
                                    >
                                        <i className="pi pi-user white" title="Profile"></i>
                                    </button>
                                    <button
                                        className="p-link layout-topbar-button user-image"
                                        style={{ color: "white" }}
                                        type="button"
                                        onClick={() => {
                                            setLogoutModal(true);
                                        }}
                                    >
                                        <i className="pi pi-power-off white" title="Logout"></i>
                                    </button>
                                </div>
                            </div>

                            <AppMenu model={userRole?.data?.role.toLowerCase().includes("user") ? userMenu : adminMenu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                        </div>
                        <div className="layout-main-container">
                            <div className="layout-main">
                                <Switch>
                                    <Route path="/" exact component={Dashboard} />
                                    <Route path="/usermanagement" component={ManageUsers} />
                                    <Route path="/userprofile" component={UserProfile} />
                                    <Route path="/adduser" component={AddUser} />
                                    <Route path="/querymanagement" component={QueryManagement} />
                                    <Route path="/viewquery/:id" component={ViewQuery} />
                                    <Route path="/inventorycart" component={InventoryCart} />
                                    <Route path="/inventorylogs/:id" component={InventoryLogs} />
                                    <Route path="/systemsettings" component={ManageSystemSetting} />
                                    <Route path="/managecompany" component={ManageCampany} />
                                    <Route path="/managecategory" component={ManageCatagory} />
                                    <Route path="/managefeature" component={ManageFeature} />
                                    <Route path="/managecarmodel" component={ManageCarModel} />
                                    <Route path="/managecar" component={ManageCar} />
                                    <Route path="/managevariant" component={ManageVariant} />
                                    <Route path="/managepart" component={ManagePart} />
                                    <Route path="/managetransmission" component={ManageTransmission} />
                                    <Route path="/managepowertype" component={ManagePowerType} />
                                    <Route path="/manageyear" component={ManageYear} />
                                    <Route path="/managebanner" component={ManageBanner} />
                                    <Route path="/managerequest" component={ManageRequest} />
                                    <Route path="/managedimension" component={ManageDimension} />
                                    <Route path="*" component={NotFound} />
                                </Switch>
                            </div>
                        </div>
                    </>
                ) : (
                    <Switch>
                        <Route path="/forgotpassword" exact component={ForgotPassword} />
                        <Route path="/otpverification" exact component={OtpVerification} />
                        <Route path="/resetpassword" exact component={ResetPassword} />
                        <Route path="*" exact component={Login} />
                    </Switch>
                )} */}
                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />
                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>{" "}
                <AppFooter />
            </div>
        </UserProvider>
    );
};

export default App;
