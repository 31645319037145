import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { handleGetRequest } from "../../../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { handlePostRequest } from "../../../../services/PostTemplate";
import SecuredFileChange from "../../../../components/SecuredFileChange/SecuredFileChange";
import classNames from "classnames";
import { Dialog } from "primereact";

const AddBanner = ({ getBannerList, onHide }) => {
    const [bannerType, setBannerType] = useState([]);
    const [selectedPrimaryFile, setSelectedPrimaryFile] = useState(null);
    const [selectedSecondryFile, setSelectedSecondryFile] = useState(null);
    const [primaryFileError, setPrimaryFileError] = useState(null);
    const [secondryFileError, setSecondryFileError] = useState(null);
    const [primarylogoLink, setPrimarylogoLink] = useState("");
    const [secondrylogoLink, setSecondrylogoLink] = useState("");
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [displayPrimaryImageDialog, setDisplayPrimaryImageDialog] = useState(false);
    const [displaySecondryImageDialog, setDisplaySecondryImageDialog] = useState(false);

    const primaryImageRef = useRef(null);
    const secondryImageRef = useRef(null);
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        typeId: Yup.string().required("This field is required"),
        primary: Yup.string().required("This field is required"),
        secondary: Yup.string().required("This field is required"),
    });

    const formik = useFormik({
        initialValues: {
            typeId: "",
            primary: "",
            secondary: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (data) => {
            setLoadingBtn(true);

            let obj = {
                typeId: data?.typeId,
                primary: selectedPrimaryFile ? selectedPrimaryFile : "",
                secondary: selectedSecondryFile ? selectedSecondryFile : "",
            };
            const response = await dispatch(handlePostRequest(obj, `/api/v1/banners/create`, false, true));
            if (response?.status === 200) {
                getBannerList();
                onHide();
            }
            setLoadingBtn(false);
        },
    });

    const getAllBannerType = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/types/getAll", false));

        if (response) {
            setBannerType(response?.data);
        }
    };

    useEffect(() => {
        getAllBannerType();
    }, []);

    const previewPrimaryImage = () => {
        setDisplaySecondryImageDialog(false);

        setDisplayPrimaryImageDialog(true);
    };
    const previewSecondryImage = () => {
        setDisplaySecondryImageDialog(true);
        setDisplayPrimaryImageDialog(true);
    };
    const closeDisplayDialog = () => {
        setDisplayPrimaryImageDialog(false);
    };

    //handle Primaryt Image Change
    const handlePrimaryImageReset = () => {
        formik.setFieldValue("primary", "");
        setSelectedPrimaryFile(null);
        setPrimarylogoLink("");
        primaryImageRef.current.value = null; // Reset the file input value
    };
    const handlePrimaryIcon = () => {
        primaryImageRef.current.click();
    };

    function handlePrimaryImageChange(event) {
        setSelectedPrimaryFile(null);
        formik.setFieldValue("primary", "");
        setPrimaryFileError("");
        const selectedFile = event.target.files[0];
        const fileTypes = ["image"];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setPrimaryFileError(error);
        } else if (newFile) {
            setPrimarylogoLink(URL.createObjectURL(selectedFile));
            formik.setFieldValue("primary", newFile?.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedPrimaryFile(base64String);
            };
            reader.readAsDataURL(newFile);
        }
    }

    //handle Secondry Image Change
    const handleSecondryImageReset = () => {
        formik.setFieldValue("secondary", "");
        setSelectedSecondryFile(null);
        setSecondrylogoLink("");
        secondryImageRef.current.value = null; // Reset the file input value
    };
    const handleSecondryIcon = () => {
        secondryImageRef.current.click();
    };

    function handleSecondryImageChange(event) {
        setSelectedSecondryFile(null);
        formik.setFieldValue("secondary", "");
        setSecondryFileError("");
        const selectedFile = event.target.files[0];
        const fileTypes = ["image"];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setSecondryFileError(error);
        } else if (newFile) {
            setSecondrylogoLink(URL.createObjectURL(selectedFile));
            formik.setFieldValue("secondary", newFile?.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedSecondryFile(base64String);
            };
            reader.readAsDataURL(newFile);
        }
    }
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <Dialog visible={displayPrimaryImageDialog} blockScroll style={{ width: "50vw" }} baseZIndex={1110} onHide={() => closeDisplayDialog()}>
                {displaySecondryImageDialog ? (
                    <img src={selectedSecondryFile} style={{ maxWidth: "100%", maxHeight: "100%", width: "900px", height: "500px", objectFit: "contain" }} />
                ) : (
                    <img src={selectedPrimaryFile} style={{ maxWidth: "100%", maxHeight: "100%", width: "900px", height: "500px", objectFit: "contain" }} />
                )}
            </Dialog>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2 mb-2">
                    <div className="field col-12 md:col-6">
                        <label>Banner Type</label>
                        <span className="Label__Required">*</span>
                        <Dropdown
                            placeholder="Select Banner Type"
                            className={classNames({ "p-invalid": isFormFieldValid("typeId") }, "Dropdown__Round")}
                            id="typeId"
                            name="typeId"
                            options={bannerType}
                            optionValue="id"
                            optionLabel="type"
                            value={formik.values.typeId}
                            filter
                            showClear={!!formik?.values?.typeId}
                            filterBy="type"
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            onChange={(e) => {
                                formik?.setFieldValue("typeId", e.target.value ? e.target.value : "");
                            }}
                        />
                        {getFormErrorMessage("typeId")}
                    </div>
                </div>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="field col-12 md:col-6">
                        <label> Upload Primary Banner </label> <span className="Label__Required">*</span>
                        <div className="file-input-container " onClick={handlePrimaryIcon}>
                            <input type="file" accept="image/*" style={{ display: "none" }} ref={primaryImageRef} onChange={handlePrimaryImageChange} />
                            <input className={classNames({ "p-invalid": isFormFieldValid("primary") }, "custom-file p-inputtext p-component Input__Round")} type="text" placeholder="Choose a file..." value={formik.values.primary} readOnly />
                            <span className="file-upload-icon">
                                <i className="pi pi-paperclip" />
                            </span>
                        </div>
                        {primaryFileError ? <small className="p-error">{primaryFileError}</small> : getFormErrorMessage("primary")}
                        {formik?.values?.primary && (
                            <div style={{ position: "relative", width: "180px", height: "180px", margin: "auto", marginTop: "15px" }}>
                                <i className="pi pi-times closingIcon" onClick={() => handlePrimaryImageReset()} style={{ cursor: "pointer", position: "absolute", top: "0", right: "0" }} />
                                <img
                                    src={primarylogoLink}
                                    alt="Banner Image"
                                    style={{ maxWidth: "100%", maxHeight: "100%", width: "150px", height: "150px", objectFit: "contain" }}
                                    onClick={() => {
                                        previewPrimaryImage();
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Upload Secondry Banner </label> <span className="Label__Required">*</span>
                        <div className="file-input-container " onClick={handleSecondryIcon}>
                            <input type="file" accept="image/*" style={{ display: "none" }} ref={secondryImageRef} onChange={handleSecondryImageChange} />
                            <input className={classNames({ "p-invalid": isFormFieldValid("secondary") }, "custom-file p-inputtext p-component Input__Round")} type="text" placeholder="Choose a file..." value={formik.values.secondary} readOnly />
                            <span className="file-upload-icon">
                                <i className="pi pi-paperclip" />
                            </span>
                        </div>
                        {secondryFileError ? <small className="p-error">{secondryFileError}</small> : getFormErrorMessage("secondary")}
                        {formik?.values?.secondary && (
                            <div style={{ position: "relative", width: "180px", height: "180px", margin: "auto", marginTop: "15px" }}>
                                <i className="pi pi-times closingIcon" onClick={() => handleSecondryImageReset()} style={{ cursor: "pointer", position: "absolute", top: "0", right: "0" }} />
                                <img
                                    src={secondrylogoLink}
                                    alt="Banner Image"
                                    style={{ maxWidth: "100%", maxHeight: "100%", width: "150px", height: "150px", objectFit: "contain" }}
                                    onClick={() => {
                                        previewSecondryImage();
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        <Button className="Save-Button ml-2" label="Add" type="submit" loading={loadingBtn} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddBanner;
