import React, { useState } from "react";
import { Button } from "primereact/button";
import loginImage from "../../assets/png/splash.png";
import { useHistory } from "react-router-dom";
import { handlePostRequest } from "../../services/PostTemplate";
import { useFormik } from "formik";
import { Password } from "primereact/password";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

const ResetPassword = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [loadingBtn, setLoadingBtn] = useState(false);

    const userEmail = sessionStorage.getItem("credentials");
    const initialEmail = (userEmail && userEmail) || "";

    const validationSchema = Yup.object().shape({
        password: Yup.string().required("Password is required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Confirm Password is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: initialEmail || "",
            password: "",
            confirmPassword: "",
        },
        onSubmit: async (data) => {
            setLoadingBtn(true);
            const result = await dispatch(handlePostRequest(data, "/api/v1/users/resetPassword", false, true));
            if (result?.status === 200) {
                sessionStorage.clear();
                history.push({
                    pathname: "/",
                });
            }
            setLoadingBtn(false);
        },
    });

    return (
        <div className="loginBgContainer">
            <img src={loginImage} alt="Image Alt Text" className="loginBgImage" />
            <div className="left-section">
                <form className="login-form" onSubmit={formik.handleSubmit}>
                    <h2 className="mb-4" style={{ color: "white", letterSpacing: "1px", fontWeight: "700", textAlign: "center" }}>
                        Reset Password{" "}
                    </h2>
                    <div className="login-div mt-6">
                        <div className="mb-2">
                            <label htmlFor="password" className="block font-medium mb-2">
                                Password<span style={{ color: "red" }}>*</span>
                            </label>
                            <Password
                                keyfilter={/[^\s]/}
                                placeholder="Enter your password"
                                id="password"
                                type="password"
                                autoComplete="off"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                className="w-12 p-inputtext-login mb-2 "
                                maxLength={15}
                                feedback={false}
                                autocomplete="off"
                                toggleMask
                            />
                            {formik.errors.password && formik.touched.password && <div style={{ color: "red" }}>{formik.errors.password}</div>}
                        </div>
                        <div>
                            <label htmlFor="confirmPassword" className="block font-medium mb-2">
                                Re-enter Password<span style={{ color: "red" }}>*</span>
                            </label>
                            <Password
                                keyfilter={/[^\s]/}
                                placeholder="Re-enter your password"
                                id="confirmPassword"
                                type="password"
                                autoComplete="off"
                                name="confirmPassword"
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                className="w-12 p-inputtext-login mb-2 "
                                maxLength={15}
                                feedback={false}
                                autocomplete="off"
                                toggleMask
                            />
                            {formik.errors.confirmPassword && formik.touched.confirmPassword && <div style={{ color: "red" }}>{formik.errors.confirmPassword}</div>}
                        </div>
                        <div className="col-12 d-flex flex-row text-center mt-5">
                            <Button className="Login-Button" label="Submit" type="submit" loading={loadingBtn} />
                            <p
                                className="text-sm mt-3 "
                                style={{ color: "#f2a037", cursor: "pointer" }}
                                onClick={() => {
                                    history.push("/");
                                    sessionStorage.clear();
                                }}
                            >
                                Back to Login
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
