const SecuredFileChange = (file, allowedTypes) => {
    console.log("selectedFile ", file?.type);
    if (file && allowedTypes && allowedTypes.length > 0) {
        if (file.size > 10 * 1024 * 1024) {
            return { file: null, error: "File size should not exceed 10MB" };
        } else {
            const fileType = file.type;
            const fileTypeMatched = allowedTypes.some((type) => fileType.includes(type));
            if (fileTypeMatched) {
                return { file, error: "" };
            } else {
                return { file: null, error: "File type not supported" };
            }
        }
    } else {
        return { file: null, error: "" };
    }
};

export default SecuredFileChange;
