import { Button } from "primereact/button";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NavigationComponent from "../../components/NavigationComponent";

const ManageSystemSetting = () => {
    const history = useHistory();

    return (
        <>
            <NavigationComponent title={`System Settings`} />

            <div className="p-fluid p-formgrid grid  mb-5 m-0 main-card bg-white card-ml-1 p-4">
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="flex justify-content-between">
                        <Button label="Makers Management" onClick={() => history.push("/managecompany")} className="systemsettings Btnn__Add__" iconPos="left" />
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="flex justify-content-between">
                        <Button label="Categories Management" onClick={() => history.push("/managecategory")} className="systemsettings Btnn__Add__" iconPos="left" />
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="flex justify-content-between">
                        <Button label="Models Management" onClick={() => history.push("/managecarmodel")} className="systemsettings Btnn__Add__" iconPos="left" />
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="flex justify-content-between">
                        <Button label="Features Management" onClick={() => history.push("/managefeature")} className="systemsettings Btnn__Add__" iconPos="left" />
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="flex justify-content-between">
                        <Button label="Dimensions Management" onClick={() => history.push("/managedimension")} className="systemsettings Btnn__Add__" iconPos="left" />
                    </div>
                </div>{" "}
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="flex justify-content-between">
                        <Button label="Banners Management" onClick={() => history.push("/managebanner")} className="systemsettings Btnn__Add__" iconPos="left" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageSystemSetting;
