import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../../services/GetTemplate";
import { handlePostRequest } from "../../../services/PostTemplate";
import classNames from "classnames";
import SecuredFileChange from "../../../components/SecuredFileChange/SecuredFileChange";
import { Dialog } from "primereact";
import { Carousel } from "primereact/carousel";

const AddParts = ({ onHide, getPartList }) => {
    const [dimension, setDimension] = useState([]);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [variantyear, setVariantYear] = useState([]);
    const [selectedFileError, setSelectedFileError] = useState(null);
    const [selectedFileArray, setSelectedFileArray] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [displayImageDialog, setDisplayImageDialog] = useState(false);

    const partImageRef = useRef(null);
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        sku_no: Yup.string()
            .matches(/^[a-zA-Z0-9\s]*$/, "Sku number must be alpha numeric")
            .matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .required("This field is required"),

        part_no: Yup.string()
            .required("This field is required")
            .matches(/^[a-zA-Z0-9\s]*$/, "Part number must be alpha numeric")
            .matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed"),

        quantity: Yup.string()?.required("Quantity should be atleast 1"),
        part_name: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .required("This field is required")
            .matches(/^[a-zA-Z0-9\s]*$/, "Part name must be alpha numeric"),
        cost: Yup.number().nullable(),
        currency: Yup.string().when("cost", {
            is: (cost) => cost !== undefined && cost !== null && cost !== "",
            then: Yup.string().required("Please select currency"),
            otherwise: Yup.string(),
        }),
        images: Yup.string().when([], {
            is: () => selectedFileArray.length === 0,
            then: Yup.string().required("You must upload at least one image"),
            otherwise: Yup.string(),
        }),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            sku_no: "",
            quantity: "1",
            part_no: "",
            part_name: "",
            is_active: "",
            dimensionsId: "",
            variantId: "",
            cost: "",
            currency: "AED",
            images: "",
            uploadedImages: "",
        },

        onSubmit: async (data) => {
            setLoadingBtn(true);
            let obj = {
                part_no: data?.part_no,
                sku_no: data?.sku_no,
                quantity: parseInt(data?.quantity),
                part_name: data?.part_name,
                partId: data?.partId,
                dimensionsId: data?.dimensionsId ? data?.dimensionsId?.toString() : undefined,
                variantId: data?.variantId ? data?.variantId : undefined,
                cost: data?.cost ? data?.cost : undefined,
                currencyCode: data?.currency ? data?.currency?.toString() : undefined,
                images: selectedFileArray ? selectedFileArray : null,
            };
            const response = await dispatch(handlePostRequest(obj, "/api/v1/parts/create", false, true));
            if (response?.status === 200) {
                getPartList();
                onHide();
            }
            setLoadingBtn(false);
        },
    });

    const getDimension = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/dimension/getAll", false));
        if (response) {
            const filteredDimension = response?.data?.filter((item) => {
                return item?.isActive == true;
            });
            setDimension(filteredDimension);
        }
    };
    const getVariant = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/variant/getAll", false));
        if (response) {
            setVariantYear(response?.data);
        }
    };
    const getCurrencies = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/currencies/getAll", false));
        if (response) {
            setCurrencies(response?.data);
        }
    };

    useEffect(() => {
        getDimension();
        getVariant();
        getCurrencies();
    }, []);

    const previewImage = () => {
        setDisplayImageDialog(true);
    };
    const closeDisplayDialog = () => {
        setDisplayImageDialog(false);
    };
    const imagePreviewer = (file) => {
        return (
            <div className="p-field col-12 md:col-12">
                <img src={file} style={{ maxWidth: "100%", maxHeight: "100%", width: "900px", height: "500px", objectFit: "contain" }} />
            </div>
        );
    };
    const responsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 3,
            numScroll: 3,
        },
    ];
    const handleDeleteImage = (index) => {
        setSelectedFileArray((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const handlePartIcon = () => {
        partImageRef.current.click();
    };

    // for multiple image upload
    function handlePartImageChange(event) {
        setSelectedFileError("");
        if (selectedFileArray?.length >= 6) {
            setSelectedFileError("You can only upload a maximum of 6 images.");
            return;
        }
        const selectedFile = event.target.files[0];
        const fileTypes = ["image"];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setSelectedFileError(error);
        } else if (newFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedFileArray((prevItems) => [...prevItems, base64String]);
            };
            reader.readAsDataURL(newFile);
        }
    }

    const variantYearOptions = variantyear?.map((item) => ({
        label: item.variantName,
        value: item.variantId,
    }));
    // console.log("hello");

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <Dialog visible={displayImageDialog} blockScroll style={{ width: "50vw" }} baseZIndex={1110} onHide={() => closeDisplayDialog()}>
                {selectedFileArray.length > 1 ? (
                    <Carousel value={selectedFileArray || []} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular={false} itemTemplate={imagePreviewer} />
                ) : (
                    <img src={selectedFileArray[0]} style={{ maxWidth: "100%", maxHeight: "100%", width: "900px", height: "500px", objectFit: "contain" }} />
                )}
            </Dialog>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="field col-12 md:col-6">
                        <label> Part Name </label>
                        <span className="Label__Required">*</span>
                        <InputText className={classNames({ "p-invalid": isFormFieldValid("part_name") }, "Input__Round")} id="part_name" name="part_name" type="text" maxLength={25} value={formik.values.part_name} onChange={formik.handleChange} placeholder="Enter Part Name" />
                        {getFormErrorMessage("part_name")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Part Number </label>
                        <span className="Label__Required">*</span>
                        <InputText className={classNames({ "p-invalid": isFormFieldValid("part_no") }, "Input__Round")} id="part_no" name="part_no" type="text" maxLength={20} value={formik.values.part_no} onChange={formik.handleChange} placeholder="Enter Part Number" />
                        {getFormErrorMessage("part_no")}
                    </div>{" "}
                    <div className="field col-12 md:col-6">
                        <label> Variant </label>
                        <span className="Label__Required"></span>
                        <Dropdown
                            className={classNames({ "p-invalid": isFormFieldValid("variantId") }, "Dropdown__Round")}
                            id="variantId"
                            name="variantId"
                            options={variantYearOptions}
                            optionValue="value"
                            optionLabel="label"
                            filter
                            showClear={!!formik?.values?.variantId}
                            filterBy="label"
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            onChange={(e) => {
                                formik?.setFieldValue("variantId", e.target.value ? e.target.value : "");
                            }}
                            value={formik.values.variantId}
                            placeholder="Select Variant"
                        />
                        {getFormErrorMessage("variantId")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Dimensions </label>
                        <span className="Label__Required"></span>
                        <Dropdown
                            className={classNames({ "p-invalid": isFormFieldValid("dimensionsId") }, "Dropdown__Round")}
                            id="dimensionsId"
                            name="dimensionsId"
                            options={dimension}
                            optionValue="dimension"
                            optionLabel="dimension"
                            filter
                            showClear={!!formik?.values?.dimensionsId}
                            filterBy="dimension"
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            onChange={(e) => {
                                formik?.setFieldValue("dimensionsId", e.target.value ? e.target.value : "");
                            }}
                            value={formik.values.dimensionsId}
                            editable
                            maxLength={30}
                            placeholder="Enter or Select"
                        />
                        {getFormErrorMessage("dimensionsId")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> SKU Number </label>
                        <span className="Label__Required">*</span>
                        <InputText className={classNames({ "p-invalid": isFormFieldValid("sku_no") }, "Input__Round")} id="sku_no" name="sku_no" type="text" maxLength={8} value={formik.values.sku_no} onChange={formik.handleChange} placeholder="Enter SKU Number" />
                        {getFormErrorMessage("sku_no")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Quantity </label>
                        <span className="Label__Required">*</span>
                        <InputText
                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                            className={classNames({ "p-invalid": isFormFieldValid("quantity") }, "Input__Round")}
                            id="quantity"
                            name="quantity"
                            type="text"
                            maxLength={7}
                            value={formik.values.quantity}
                            onChange={formik.handleChange}
                            placeholder="Enter Quantity"
                        />
                        {getFormErrorMessage("quantity")}
                    </div>
                    <div className="flex field col-12 md:col-6">
                        <div className="field col-12 md:col-6 ">
                            <label> Price </label>
                            <span className="Label__Required"></span>
                            <InputText keyfilter={/^[0-9]*(\.[0-9]*)?$/} className={classNames({ "p-invalid": isFormFieldValid("cost") }, "Input__Round")} id="cost" maxLength={8} name="cost" type="text" value={formik.values.cost} onChange={formik.handleChange} placeholder="Enter Price" />
                            {getFormErrorMessage("cost")}
                        </div>{" "}
                        <div className="field col-12 md:col-6 ml-0">
                            <label> Currency </label>
                            <span className="Label__Required"></span>
                            <Dropdown
                                placeholder="Select currency"
                                className={classNames({ "p-invalid": isFormFieldValid("currency") }, "Dropdown__Round")}
                                id="currency"
                                name="currency"
                                options={currencies}
                                optionValue="code"
                                optionLabel="code"
                                value={formik.values.currency}
                                filter
                                showClear={!!formik?.values?.currency}
                                filterBy="code"
                                showFilterClear={true}
                                resetFilterOnHide={true}
                                onChange={(e) => {
                                    formik?.setFieldValue("currency", e.target.value ? e.target.value : "");
                                }}
                            />
                            {getFormErrorMessage("currency")}
                        </div>
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Upload Images </label> <span className="Label__Required">*</span>
                        <div className="file-input-container " onClick={handlePartIcon}>
                            <input type="file" accept="image/*" style={{ display: "none" }} ref={partImageRef} onChange={handlePartImageChange} disabled={selectedFileArray?.length >= 6} />
                            <input className={classNames({ "p-invalid": isFormFieldValid("images") }, "custom-file p-inputtext p-component Input__Round")} type="text" placeholder="Choose a file..." value={formik.values.images} readOnly disabled={selectedFileArray?.length >= 6} />
                            <span className="file-upload-icon">
                                <i className="pi pi-paperclip" />
                            </span>
                        </div>
                        {selectedFileError ? <small className="p-error">{selectedFileError}</small> : getFormErrorMessage("images")}
                    </div>
                    <div className="field col-12 md:col-12">
                        <div>
                            <label> Uploaded Images </label>
                        </div>
                        <div className=" border-solid border-900 border-2 border-round p-5 mt-2">
                            {selectedFileArray.map((item, index) => {
                                return (
                                    <div key={index} style={{ position: "relative", display: "inline-block", margin: "5px", width: "110px", height: "110px" }}>
                                        <i className="pi pi-times closingIcon" onClick={() => handleDeleteImage(index)} style={{ cursor: "pointer", position: "absolute", top: "0", right: "0" }} />
                                        <img
                                            crossOrigin="anonymous"
                                            src={item}
                                            style={{ width: "90px", height: "90px", objectFit: "contain" }}
                                            alt="Part Image"
                                            onClick={() => {
                                                previewImage();
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        <Button className="Save-Button ml-2" label="Add" type="submit" loading={loadingBtn} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddParts;
