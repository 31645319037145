/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import * as Yup from "yup";
import classNames from "classnames";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../services/GetTemplate";
import { handlePatchRequest } from "../../services/PatchTemplate";
import { Dialog } from "primereact/dialog";
import Avatar from "react-avatar-edit";
import { baseURL } from "../../Config";
import Loading from "../../components/Loader";
import NavigationComponent from "../../components/NavigationComponent";
import UpdatePassword from "./UpdatePassword";
import profilepng from "../../assets/png/dummyimage.png";
import { UserContext } from "../../components/UserContext";

const UserProfile = ({ isEditable }) => {
    const [allRoles, setAllRoles] = useState([]);
    const [previousImage, setPreviousImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [logo, setLogo] = useState(null);
    const [imageCrop, setImageCrop] = useState(false);
    const [profile, setProfile] = useState([]);
    const [pview, setPview] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [displayDialog, setDisplayDialog] = useState(false);

    const { setUser } = useContext(UserContext);

    const profileFinal = profile.map((item) => item.pview);
    const dispatch = useDispatch();

    const userIdStr = JSON.parse(localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails"));
    const userId = userIdStr?.data?.id;

    const getUserById = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest(`/api/v1/users/getById/${userId}`, false));
        if (response?.status === 200) {
            const keyData = response?.data[0];
            formik.setFieldValue("name", keyData?.name);
            formik.setFieldValue("phone", keyData?.phone);
            formik.setFieldValue("email", keyData?.email);
            formik.setFieldValue("role", keyData?.role?.toString());
            setPreviousImage(keyData?.profileImage ? baseURL + "/" + keyData?.profileImage : profilepng);
        }
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    useEffect(() => {
        getAllRoles();
    }, []);

    useEffect(() => {
        getUserById();
    }, []);

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .required("This field is required")
            .matches(/^[a-zA-Z0-9\s]*$/, "Name must be alpha numeric"),
        phone: Yup.string()
            .matches(/^\d{9,11}$/, "Field must be between 9 and 11 digits")
            .required("This field is required"),

        role: Yup.string().required("This field is required"),
        email: Yup.string().required("This field is required").email("Invalid email format"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            phone: "",
            role: "",
            email: "",
            password: "",
        },
        onSubmit: async (data) => {
            setLoadingBtn(true);

            let newData = {
                userId: userId,
                name: data?.name,
                phone: data?.phone,
                role: data?.role,
                email: data?.email,
                password: data?.password ? data?.password : undefined,
                profileImage: logo ? logo : "",
            };

            const res = await dispatch(handlePatchRequest(newData, `/api/v1/users/update`, false, true));
            if (res.status === 200) {
                if (logo) {
                    userIdStr.data.profileImage = logo;
                }
                userIdStr.data.name = newData?.name;
                {
                    localStorage.getItem("rememberMe") === "y" ? localStorage.setItem("userDetails", JSON.stringify(userIdStr)) : sessionStorage.setItem("userDetails", JSON.stringify(userIdStr));
                }
                setUser({
                    name: newData?.name,
                    role: newData?.role,
                    image: logo ? logo : userIdStr?.data?.profileImage,
                });
            }
            setLoadingBtn(false);
        },
    });

    const getAllRoles = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/roles/getAll", false));
        setAllRoles(response?.data);
    };

    const handleDialog = () => {
        // setCurrentModal("Add");
        setDisplayDialog(true);
    };

    const onClose = () => {
        setPview(null);
    };
    const onHide = () => {
        // setCurrentModal(null);
        setDisplayDialog(false);
    };
    const onCrop = (view) => {
        setPview(view);
    };
    const saveCropImage = () => {
        setProfile([...profile, { pview }]);
        setImageCrop(false);
        setLogo(pview);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const headerText = "Update Password";
    const header = <span className="title-border">{headerText}</span>;

    return (
        <div>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Dialog resizable={false} header={header} visible={displayDialog} blockScroll style={{ width: "40vw" }} onHide={() => onHide()} draggable={false}>
                        <UpdatePassword onHide={onHide} userdataId={userId} isEditable={isEditable} />
                    </Dialog>
                    <NavigationComponent title={`User Profile`} />
                    <div className="card-ml-1 dark-bg md:col-12 mx-auto">
                        <form className="p-fluid p-mt-2 col-8 mx-auto p-5" onSubmit={formik.handleSubmit}>
                            <div className="text-center mb-2">
                                <div className="flex flex-column justify-content-center align-items-center">
                                    <img style={{ width: "150px", height: "150px", borderRadius: "50%", objectFit: "cover", border: "4px solid black" }} src={profileFinal.length ? profileFinal : previousImage} alt="" />
                                    <Button onClick={() => setImageCrop(true)} type="button" label="Upload Image" className="Save-Button-Profile mt-3" />
                                    <Dialog visible={imageCrop} onHide={() => setImageCrop(false)}>
                                        <div>
                                            <Avatar width={500} height={400} onCrop={onCrop} onClose={onClose} shadingColor={"#474649"} backgroundColor={"474649"} />
                                            <div className="flex flex-column align-items-center w-12 mt-5">
                                                <div className="flex justify-content-around w-12 mt-4">
                                                    <Button onClick={saveCropImage} label="Save" icon="pi pi-check" type="button" className="Save-Button" />
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                            <div className="p-fluid formgrid grid pl-2 pr-2">
                                <div className="p-field col-12 md:col-6">
                                    <div className="field mt-3">
                                        <label>Name</label> <span className="Label__Required">*</span>
                                        <InputText id="name" placeholder="Enter Name" name="name" value={formik?.values?.name} maxLength={30} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__Round")} />
                                        {getFormErrorMessage("name")}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-6">
                                    <div className="field mt-3">
                                        <label>Phone No</label> <span className="Label__Required">*</span>
                                        <InputText keyfilter="int" id="phone" placeholder="Enter Phone No" name="phone" value={formik?.values?.phone} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("phone") }, "Input__Round")} />
                                        {getFormErrorMessage("phone")}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-6">
                                    <div className="field mt-4">
                                        <label htmlFor="role" className={classNames({ "p-error": isFormFieldValid("role") }, "Label__Text")}>
                                            Role
                                        </label>{" "}
                                        <span className="Label__Required">*</span>
                                        <Dropdown
                                            id="role"
                                            placeholder="Select Role"
                                            options={allRoles}
                                            optionLabel="name"
                                            name="role"
                                            optionValue="name"
                                            value={formik.values.role || ""}
                                            onChange={formik.handleChange}
                                            className={classNames({ "p-invalid": isFormFieldValid("role") }, "Dropdown__Round")}
                                            disabled
                                        />
                                        {getFormErrorMessage("role")}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-6">
                                    <div className="field mt-4">
                                        <label htmlFor="email" className="Label__Text">
                                            Email
                                        </label>{" "}
                                        <span className="Label__Required">*</span>
                                        <InputText className={classNames({ "p-invalid": isFormFieldValid("email") }, "Input__Round")} placeholder="Enter Email" id="email" name="email" value={formik.values.email} onChange={formik.handleChange} type="text" />
                                        {getFormErrorMessage("email")}
                                    </div>
                                </div>
                                {isEditable === true && (
                                    <>
                                        <div className="p-field col-12 md:col-6">
                                            <div className="field mt-4">
                                                <label htmlFor="password" className="Label__Text">
                                                    Password
                                                </label>
                                                <InputText className={classNames({ "p-invalid": isFormFieldValid("password") }, "Input__Round")} id="password" name="password" value={formik.values.password} onChange={formik.handleChange} autocomplete="off" />
                                                {getFormErrorMessage("password")}
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                                    <Button className="w-auto border-radius-12px" label="Update Password" type="button" onClick={handleDialog} />
                                    <Button className="w-auto border-radius-12px ml-2" label="Update Profile" type="submit" loading={loadingBtn} />
                                </div>
                            </div>
                        </form>
                    </div>
                </>
            )}
        </div>
    );
};

export default UserProfile;
