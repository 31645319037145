import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch } from "react-redux";
import editIcon from "../../assets/png/edit.png";
import { handleGetRequest } from "../../services/GetTemplate";
import EditRequest from "./EditRequest";
import { Dialog } from "primereact/dialog";
import Loading from "../../components/Loader";
import { useHistory } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";

const ManageRequest = () => {
    const [allRequest, setAllRequest] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [requestdataId, setRequestDataId] = useState();
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const dispatch = useDispatch();
    // const history = useHistory();

    const onHide = () => {
        setDisplayDialog(false);
    };

    const getRequestList = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest(`/api/v1/requests/getAll`, false));
        setAllRequest(response?.data);
        setLoading(false);
    };

    useEffect(() => {
        getRequestList();
    }, []);
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ""));
    };

    const statusTemplate = (rowData) => {
        const getStatusColor = (status) => {
            switch (status) {
                case true:
                    return "#008000";
                case false:
                    return "#FF0000";
                default:
                    return "#FFA500";
            }
        };

        const statusColor = getStatusColor(rowData?.isApproved);
        const statusText = rowData?.isApproved ? "Approved" : rowData?.isApproved == false ? "Rejected" : "Pending";

        return (
            <p style={{ color: statusColor }}>
                <b>{statusText}</b>
            </p>
        );
    };

    const handleEditRequest = (rowData) => {
        setDisplayDialog(true);
        setRequestDataId(rowData?.id);
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                <Button className="edit-btn ml-3" onClick={() => handleEditRequest(rowData)}>
                    <img src={editIcon} alt="Edit" />
                </Button>
            </div>
        );
    };

    const headerText = "Edit";
    const header = <span className="title-border">{headerText}</span>;

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Dialog header={header} visible={displayDialog} blockScroll style={{ width: "40vw" }} onHide={() => onHide()} draggable={false}>
                        <EditRequest onHide={onHide} requestdataId={requestdataId} getRequestList={getRequestList} />
                    </Dialog>
                    <div className="p-fluid p-formgrid grid mb-5 m-0">
                        <div className="p-field col-12 md:col-6">
                            <div className="p-field">
                                <h2 style={{ fontWeight: "700", color: "black" }}>Request Management </h2>
                            </div>
                        </div>
                    </div>
                    <div className="card-ml-1 dark-bg">
                        <DataTable
                            header={
                                <div className="flex justify-content-end custom-alignment" style={{ display: "flex", alignItems: "center" }}>
                                    <InputText className="Input__Round" placeholder="Search..." value={globalFilterValue} onChange={onGlobalFilterChange} />
                                </div>
                            }
                            responsive={true}
                            filters={filters}
                            globalFilterFields={["name", "phone", "query", "email"]}
                            responsiveLayout="scroll"
                            stripedRows
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            value={allRequest}
                            emptyMessage="No records found"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        >
                            <Column field="name" header="Name" style={{ width: "150px" }} />
                            <Column field="phone" header="Phone No" style={{ width: "150px" }} />
                            <Column field="email" header="Email" style={{ width: "150px" }} />
                            <Column field="query" header="Query" style={{ width: "150px" }} />
                            <Column body={statusTemplate} header="Status" style={{ width: "150px" }} />
                            <Column body={actionTemplate} header="Action" style={{ width: "150px" }} />
                        </DataTable>
                    </div>
                </>
            )}
        </>
    );
};

export default ManageRequest;
