// DotLoader.jsx
import React from "react";
import "../App.scss"; // Ensure the path is correct

const Loading = () => {
    return (
        <div className="dot-loader">
            <div className="dot-container">
                <div className="dot red"></div>
                <div className="dot orange"></div>
                <div className="dot red"></div>
            </div>
        </div>
    );
};

export default Loading;
