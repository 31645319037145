import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import loginImage from "../../assets/png/splash.png";
import { useHistory } from "react-router-dom";
import { handlePostRequest } from "../../services/PostTemplate";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

const OtpVerification = () => {
    const history = useHistory();
    const inputRefs = useRef([]);
    const dispatch = useDispatch();
    const [isResending, setIsResending] = useState(false);
    const [otpValidation, setOtpvalidation] = useState("");
    const [timer, setTimer] = useState(120); // Timer for resend button
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [resendLoadingBtn, setResendLoadingBtn] = useState(false);

    const validationSchema = Yup.object().shape({});
    const userEmail = sessionStorage.getItem("credentials");
    const initialEmail = (userEmail && userEmail) || "";

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: initialEmail || "",
            otp: "",
        },
        onSubmit: async (data) => {
            setLoadingBtn(true);

            if (!formik.values.otp) {
                setOtpvalidation("OTP is required");
            } else if (formik.values.otp.length !== 4) {
                setOtpvalidation("OTP length should be 4 digits");
            } else {
                const result = await dispatch(handlePostRequest(data, "/api/v1/users/verifyOtp", false, true));
                if (result?.status === 200) {
                    sessionStorage.setItem("credentials", data?.email);
                    history.push({
                        pathname: "/resetpassword",
                    });
                } else {
                    formik.setFieldValue("otp", "");
                }
            }
            setLoadingBtn(false);
        },
    });

    useEffect(() => {
        // Start countdown when component mounts
        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 1) {
                    clearInterval(countdown);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);

        return () => clearInterval(countdown); // Clear interval on unmount
    }, [timer]);

    const handleInputChange = (index, e) => {
        const { value } = e.target;
        if (/^[0-9]$/.test(value)) {
            // Allow only digits
            const newOtp = [...formik.values.otp];
            newOtp[index] = value;
            formik.setFieldValue("otp", newOtp.join(""));
            if (index < inputRefs.current.length - 1 && value) {
                inputRefs.current[index + 1].focus();
            }
        } else if (value === "") {
            const newOtp = [...formik.values.otp];
            newOtp[index] = value;
            formik.setFieldValue("otp", newOtp.join(""));
        }
    };

    const handleResendOtp = async () => {
        formik.setFieldValue("otp", "");

        setResendLoadingBtn(true);

        setIsResending(true);
        try {
            const data = { email: formik.values.email };
            const result = await dispatch(handlePostRequest(data, "/api/v1/users/sendOtp", false, true));
            if (result?.status === 200) {
                setTimer(120);
            } else {
            }
        } catch (error) {
        } finally {
            setIsResending(false);
        }
        setResendLoadingBtn(false);
    };

    return (
        <div className="loginBgContainer">
            <img src={loginImage} alt="img txt" className="loginBgImage" />
            <div className="left-section">
                <form className="login-form" onSubmit={formik.handleSubmit}>
                    {" "}
                    <i
                        class="pi pi-arrow-circle-left"
                        style={{ fontSize: "2em", cursor: "pointer" }}
                        onClick={() => {
                            history.goBack();
                        }}
                    ></i>
                    <h2 className="mb-4" style={{ color: "white", letterSpacing: "1px", fontWeight: "700", textAlign: "center" }}>
                        Verify OTP
                    </h2>
                    <div className="login-div mt-6">
                        <div className="otp-container">
                            {[...Array(4)].map((_, index) => (
                                <input
                                    key={index}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    type="text"
                                    maxLength="1"
                                    className="otp-box"
                                    value={formik.values.otp[index] || ""}
                                    onChange={(e) => {
                                        handleInputChange(index, e);
                                        setOtpvalidation("");
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Backspace" && !formik.values.otp[index] && index > 0) {
                                            inputRefs.current[index - 1].focus();
                                        }
                                    }}
                                />
                            ))}
                        </div>{" "}
                        <div className="flex justify-content-center mt-3">
                            <small className="p-error">{otpValidation}</small>
                        </div>
                        <div className="col-12 d-flex flex-row text-center mt-5">
                            <Button className="Login-Button" label="Submit" type="submit" loading={loadingBtn} />
                        </div>
                        <div className="col-12 d-flex flex-row text-center mt-3">
                            <Button className="Resend-Button" label={timer > 0 ? `Resend OTP (${Math.floor(timer / 60)}m ${timer % 60}s)` : "Resend OTP"} onClick={handleResendOtp} disabled={isResending || timer > 0} loading={resendLoadingBtn} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default OtpVerification;
