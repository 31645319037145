import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { handlePutRequest } from "../../../../services/PutTemplate";
import { InputTextarea } from "primereact/inputtextarea";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../../../services/GetTemplate";
import Loading from "../../../../components/Loader";

import classNames from "classnames";

const EditViewCategory = ({ onHide, getCategoryList, categorydataId, isEditable }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const getCategoryById = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest(`/api/v1/category/ById/${categorydataId}`, false));
        if (response?.status === 200) {
            const keyData = response?.data;
            formik.setFieldValue("name", keyData?.name);
            formik.setFieldValue("description", keyData?.description);
        }
        setLoading(false);
    };

    useEffect(() => {
        getCategoryById();
    }, [categorydataId]);

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            ?.matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .required("This field is required"),
        description: Yup.string()
            .required("This field is required")
            .matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            description: "",
        },

        onSubmit: async (data) => {
            setLoadingBtn(true);

            let obj = {
                name: data?.name,
                description: data?.description,
            };
            const response = await dispatch(handlePutRequest(obj, `/api/v1/category/update/${categorydataId}`, false, true));
            if (response?.status === 200) {
                getCategoryList();
                onHide();
            }
            setLoadingBtn(false);
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            {loading ? <Loading /> : <></>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="field col-12 md:col-6">
                        <label> Category Name </label>
                        <span className="Label__Required">*</span>
                        <InputText placeholder="Enter Category Name" className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__Round")} id="name" name="name" maxLength={30} value={formik.values.name} onChange={formik.handleChange} type="text" disabled={!isEditable} />
                        {getFormErrorMessage("name")}
                    </div>
                    <div className="field col-12 md:col-12">
                        <label> Description</label>
                        <span className="Label__Required">*</span>
                        <InputTextarea
                            id="description"
                            name="description"
                            placeholder="Enter Category Description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            rows={5}
                            cols={10}
                            autoResize
                            maxLength={250}
                            className={classNames({ "p-invalid": isFormFieldValid("description") }, "Input__Round")}
                            disabled={!isEditable}
                        />

                        {getFormErrorMessage("description")}
                    </div>

                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        {isEditable === true && <Button className="Save-Button ml-2" label="Update" type="submit" loading={loadingBtn} />}
                    </div>
                </div>
            </form>
        </>
    );
};

export default EditViewCategory;
