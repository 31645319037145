import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../../services/GetTemplate";
import Loading from "../../../components/Loader";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import NavigationComponent from "../../../components/NavigationComponent";

const InventoryLogs = () => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState([]);
    const [partId, setPartId] = useState(null);

    const history = useHistory();

    const { id: inventoryId } = useParams();

    const getInventoryLogs = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest(`/api/v1/inventories/inventoryLogs/${inventoryId}`, false));
        setLogs(response?.data);
        setPartId(response?.data?.[0]?.partId);
        setLoading(false);
    };

    useEffect(() => {
        getInventoryLogs();
    }, [inventoryId]);

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, "0");
        const dd = String(date.getDate()).padStart(2, "0");
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");

        let ampm = "AM";
        if (hours >= 12) {
            ampm = "PM";
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    const updatedDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, "0");
        const dd = String(date.getDate()).padStart(2, "0");
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");

        let ampm = "AM";
        if (hours >= 12) {
            ampm = "PM";
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <NavigationComponent title={`Inventory Logs`} isBackButtonLink={() => history.goBack()} />

                    {partId !== null && partId !== undefined ? (
                        <div className="card-ml-1 dark-bg">
                            <DataTable
                                header={<div className="flex justify-content-end custom-alignment" style={{ display: "flex", alignItems: "center" }}></div>}
                                responsive={true}
                                responsiveLayout="scroll"
                                stripedRows
                                paginator
                                rows={20}
                                value={logs}
                                emptyMessage="No records found"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            >
                                <Column field="logId" header="Log Id" style={{ width: "150px" }}></Column>
                                <Column field="partName" header="Part Name" style={{ width: "150px" }}></Column>
                                <Column field="variantName" header="Variant Name" style={{ width: "150px" }}></Column>
                                <Column field="quantity" header="Quantity" style={{ width: "150px" }}></Column>
                                <Column field="cost" header="Cost" style={{ width: "150px" }}></Column>
                                <Column body={createdDateAndTimeBodyTemplate} header="Created Date" style={{ width: "150px" }} />
                                <Column body={updatedDateAndTimeBodyTemplate} header="Updated Date" style={{ width: "150px" }} />
                            </DataTable>
                        </div>
                    ) : (
                        <div className="card-ml-1 dark-bg">
                            <DataTable
                                header={<div className="flex justify-content-end custom-alignment" style={{ display: "flex", alignItems: "center" }}></div>}
                                responsive={true}
                                responsiveLayout="scroll"
                                stripedRows
                                paginator
                                rows={20}
                                value={logs}
                                emptyMessage="No records found"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            >
                                <Column field="logId" header="Log Id" style={{ width: "150px" }}></Column>
                                <Column field="vehicleModelName" header="Vehicle Model Name" style={{ width: "150px" }}></Column>
                                <Column field="variantName" header="Vehicle Variant Name" style={{ width: "150px" }}></Column>
                                <Column field="quantity" header="Quantity" style={{ width: "150px" }}></Column>
                                <Column field="cost" header="Cost" style={{ width: "150px" }}></Column>
                                <Column body={createdDateAndTimeBodyTemplate} header="Created Date" style={{ width: "150px" }} />
                                <Column body={updatedDateAndTimeBodyTemplate} header="Updated Date" style={{ width: "150px" }} />
                            </DataTable>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default InventoryLogs;
