import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { handlePostRequest } from "../../../../services/PostTemplate";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import classNames from "classnames";

const AddPowerType = ({ onHide, getPowerTypeList }) => {
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        power_Type: Yup.string()
            .required("This field is required")
            .min(3, "field must be at least 3 characters long")
            .max(50, "field cannot exceed 50 characters")
            .matches(/^[a-zA-Z ]+$/, "field can only contain alphabets"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            power_Type: "",
            isActive: true,
        },

        onSubmit: async (data) => {
            let obj = {
                power_Type: data?.power_Type,
                isActive: data?.isActive,
            };
            const response = await dispatch(handlePostRequest(obj, "/api/v1/powerTypes/create", false, true));
            if (response?.status === 200) {
                getPowerTypeList();
                onHide();
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="field col-12 md:col-6">
                        <label> Power Type </label>
                        <span className="Label__Required">*</span>
                        <InputText placeholder="Enter Power Type" className={classNames({ "p-invalid": isFormFieldValid("power_Type") }, "Input__Round")} id="power_Type" name="power_Type" maxLength={30} type="text" value={formik.values.power_Type} onChange={formik.handleChange} />
                        {getFormErrorMessage("power_Type")}
                    </div>

                    <div className="flex flex-wrap gap-3 ">
                        <div className="flex align-items-center ml-3">
                            <RadioButton
                                inputId="active"
                                value={true}
                                onChange={(e) => {
                                    formik.setFieldValue("isActive", e.value);
                                }}
                                checked={formik.values.isActive === true}
                            />
                            <label htmlFor="isActive" className="ml-2">
                                Active
                            </label>
                        </div>
                        <div className="flex align-items-center ml-5">
                            <RadioButton
                                inputId="inactive"
                                value={false}
                                onChange={(e) => {
                                    formik.setFieldValue("isActive", e.value);
                                }}
                                checked={formik.values.isActive === false}
                            />
                            <label htmlFor="isActive" className="ml-2">
                                Inactive
                            </label>
                        </div>
                    </div>

                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        <Button className="Save-Button ml-2" label="Add" type="submit" />
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddPowerType;
