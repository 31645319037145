import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import Modal from "../../../../components/modal/Modal";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { handleGetRequest } from "../../../../services/GetTemplate";
import { useDispatch } from "react-redux";
import editIcon from "../../../../assets/png/edit.png";
import deleteIcon from "../../../../assets/png/delete.png";
import viewIcon from "../../../../assets/png/view.png";
import { handleDeleteRequest } from "../../../../services/DeleteTemplate";
import Loading from "../../../../components/Loader";
import AddTransmission from "./AddTransmission";
import EditViewTransmission from "./EditViewTransmission";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";

const ManageTransmission = () => {
    const [displayDialog, setDisplayDialog] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [allTransmission, setAllTransmission] = useState([]);
    const [transmissiondataId, setTransmissionDataId] = useState();
    const [loading, setLoading] = useState(false);
    const [currentModal, setCurrentModal] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        transmission: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const dispatch = useDispatch();
    const history = useHistory();

    const getTransmissionList = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest("/api/v1/transmission/getAllP", false));
        if (response) {
            setAllTransmission(response?.data);
        }
        setLoading(false);
    };

    useEffect(() => {
        getTransmissionList();
    }, []);

    const handleDeleteTransmission = async () => {
        const response = await dispatch(handleDeleteRequest(`/api/v1/transmission/delete/${modalData?.id}`, true, true));
        if (response?.status === 200) {
            getTransmissionList();
        }
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const onHide = () => {
        setDisplayDialog(false);
        setCurrentModal(null);
    };

    const handleDialog = () => {
        setCurrentModal("Add");
        setDisplayDialog(true);
    };

    const handleEditTransmission = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setIsEditable(true);
        setTransmissionDataId(rowData.id);
    };
    const handleViewTransmission = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setIsEditable(false);
        setTransmissionDataId(rowData.id);
    };

    const statusField = (rowData) => {
        const statusName = rowData?.isActive ? "Active" : "Inactive";
        const statusStyle = {
            color: rowData?.isActive ? "green" : "red",
            fontWeight: "bold",
        };

        return <span style={statusStyle}>{statusName}</span>;
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ""));
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                <Button className="edit-btn ml-3" onClick={() => handleEditTransmission(rowData)}>
                    <img src={editIcon} alt="Edit" />
                </Button>
                <Button className="edit-btn ml-3" onClick={() => handleViewTransmission(rowData)}>
                    <img src={viewIcon} alt="Edit" />
                </Button>
                <Button
                    disabled={!rowData?.isActive}
                    className="edit-btn ml-3"
                    onClick={() => {
                        handleOpenModal();
                        setModalData(rowData);
                    }}
                >
                    <img src={deleteIcon} alt="Delete" />
                </Button>
            </div>
        );
    };

    const headerText = currentModal === "Add" ? "Add" : isEditable === true ? "Edit" : "View";
    const header = <span className="title-border">{headerText}</span>;

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Dialog resizable={false} header={header} visible={displayDialog} blockScroll style={{ width: "40vw" }} onHide={() => onHide()} draggable={false}>
                        {currentModal === "Add" ? (
                            <AddTransmission onHide={onHide} getTransmissionList={getTransmissionList} />
                        ) : (
                            currentModal === "EditView" && <EditViewTransmission onHide={onHide} transmissiondataId={transmissiondataId} getTransmissionList={getTransmissionList} isEditable={isEditable} />
                        )}
                    </Dialog>

                    <div>
                        <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleDeleteTransmission} rowData={modalData} headerText="Are you sure you want to delete this Transmission?" />
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5 m-0">
                        <div className="p-field col-12 md:col-6">
                            <div className="p-field">
                                <h2 style={{ fontWeight: "700", color: "black" }}>Transmission Management </h2>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-6">
                            <div className="p-field">
                                <div>
                                    <Button
                                        label="Add New"
                                        className="Btn__Add"
                                        icon="pi pi-plus-circle"
                                        iconPos="left"
                                        onClick={() => {
                                            handleDialog();
                                        }}
                                    />
                                    <Button label="Back" className="Btn__Back" icon="pi pi-arrow-left" onClick={() => history.goBack()} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-ml-1 dark-bg">
                        <DataTable
                            header={
                                <div className="flex justify-content-end custom-alignment" style={{ display: "flex", alignItems: "center" }}>
                                    <InputText className="Input__Round" placeholder="Search..." value={globalFilterValue} onChange={onGlobalFilterChange} />
                                </div>
                            }
                            filters={filters}
                            globalFilterFields={["transmission"]}
                            responsive={true}
                            responsiveLayout="scroll"
                            stripedRows
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            value={allTransmission}
                            emptyMessage="No records found"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        >
                            <Column field="transmission" header="Transmission" style={{ width: "150px" }} />
                            <Column body={statusField} header="Status" style={{ width: "150px" }} />
                            <Column header="Action" body={actionTemplate} style={{ width: "150px" }} />
                        </DataTable>
                        <Modal />
                    </div>
                </>
            )}
        </>
    );
};

export default ManageTransmission;
