import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import editIcon from "../../../../assets/png/edit.png";
import deleteIcon from "../../../../assets/png/delete.png";
import Modal from "../../../../components/modal/Modal";
import viewIcon from "../../../../assets/png/view.png";
import { handleGetRequest } from "../../../../services/GetTemplate";
import { handleDeleteRequest } from "../../../../services/DeleteTemplate";
import Loading from "../../../../components/Loader";
import AddDimensions from "./AddDimensions";
import EditViewDimension from "./EditViewDimension";
import { useHistory } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { handlePutRequest } from "../../../../services/PutTemplate";
import { Dropdown } from "primereact/dropdown";
import NavigationComponent from "../../../../components/NavigationComponent";

const ManageDimension = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allDimension, setAllDimension] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [isEditable, setIsEditable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentModal, setCurrentModal] = useState(null);
    const [dimensiondataId, setdimensionDataId] = useState();
    const [displayDialog, setDisplayDialog] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        updatedStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [switchModal, setSwitchModal] = useState(false);
    const [switchData, setSwitchData] = useState(null);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [statusValue, setStatusValue] = useState("all");
    const [filteredDimensions, setFilteredDimensions] = useState([]);

    const statusOptions = [
        { id: "all", name: "All", className: "status-all" },
        { id: "active", name: "Active", className: "status-active" },
        { id: "inactive", name: "Inactive", className: "status-inactive" },
    ];
    const dispatch = useDispatch();
    const history = useHistory();

    const userIdStr = localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails");

    const userId = JSON.parse(userIdStr);
    const userRole = userId?.data?.role;

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ""));
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                {!userRole?.toLowerCase().includes("user") && (
                    <InputSwitch
                        className="edit-btn ml-3 mt-2"
                        checked={rowData.isActive == "1"}
                        onChange={() => {
                            handleSwitchModalOpen();
                            setSwitchData(rowData);
                        }}
                    />
                )}
                {!userRole?.toLowerCase().includes("user") && (
                    <Button className="edit-btn ml-5" onClick={() => handleEditDimension(rowData)}>
                        <img src={editIcon} alt="Edit" />
                    </Button>
                )}
                <Button className="edit-btn ml-3" onClick={() => handleViewDimension(rowData)}>
                    <img src={viewIcon} alt="View" />
                </Button>
                {!userRole?.toLowerCase().includes("user") && (
                    <Button
                        className="edit-btn ml-3"
                        onClick={() => {
                            handleOpenModal();
                            setModalData(rowData);
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" />
                    </Button>
                )}
            </div>
        );
    };
    const handleInputSwitch = async () => {
        setLoadingBtn(true);

        let obj = {
            isActive: switchData?.isActive ? false : true,
        };

        const response = await dispatch(handlePutRequest(obj, `/api/v1/dimension/update/${switchData?.id}`, false, true));

        if (response?.status === 200) {
            getDimensionList();
        }
        setLoadingBtn(false);
        handleSwitchModalClose();
    };
    const handleSwitchModalOpen = () => {
        setSwitchModal(true);
    };
    const handleSwitchModalClose = () => {
        setSwitchModal(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const onHide = () => {
        setDisplayDialog(false);
        setCurrentModal(null);
    };

    const handleDialog = () => {
        setCurrentModal("Add");
        setDisplayDialog(true);
    };

    const handleEditDimension = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setIsEditable(true);
        setdimensionDataId(rowData.id);
    };
    const handleViewDimension = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setIsEditable(false);
        setdimensionDataId(rowData.id);
    };

    const getDimensionList = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest("/api/v1/dimension/getAllP", false));
        if (response) {
            setAllDimension(response?.data);
        }
        setLoading(false);
    };

    useEffect(() => {
        getDimensionList();
    }, []);
    useEffect(() => {
        const updatedResponse = allDimension?.filter((item) => {
            if (statusValue === "active") return item?.isActive;
            else if (statusValue === "inactive") return !item?.isActive;
            else return item;
        });
        setFilteredDimensions(updatedResponse);
    }, [statusValue, allDimension]);

    const handleDeleteModel = async () => {
        setLoadingBtn(true);

        const response = await dispatch(handleDeleteRequest(`/api/v1/dimension/delete/${modalData?.id}`, true, true));
        if (response?.status === 200) {
            getDimensionList();
        }
        setLoadingBtn(false);
        handleCloseModal();
    };

    const optionTemplate = (option) => {
        return <div className={option.className}>{option.name}</div>;
    };
    const selectedItemTemplate = (option) => {
        if (option) {
            return <div className={option.className}>{option.name}</div>;
        }
        return <span>Select Status</span>;
    };
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, "0");
        const dd = String(date.getDate()).padStart(2, "0");
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");

        let ampm = "AM";
        if (hours >= 12) {
            ampm = "PM";
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    const headerText = currentModal === "Add" ? "Add Dimension" : isEditable === true ? "Edit Dimension" : "View Dimension";
    const header = <span className="title-border">{headerText}</span>;

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Dialog header={header} visible={displayDialog} style={{ width: "40vw" }} onHide={() => onHide()}>
                        {currentModal === "Add" ? <AddDimensions onHide={onHide} getDimensionList={getDimensionList} /> : currentModal === "EditView" && <EditViewDimension onHide={onHide} dimensiondataId={dimensiondataId} getDimensionList={getDimensionList} isEditable={isEditable} />}
                    </Dialog>
                    <div>
                        <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleDeleteModel} rowData={modalData} type="delete" loadingBtn={loadingBtn} headerText="Are you sure you want to delete this Dimension?" />
                    </div>
                    <div>
                        <Modal
                            isOpen={switchModal}
                            onClose={handleSwitchModalClose}
                            onConfirm={handleInputSwitch}
                            type="active/inactive"
                            loadingBtn={loadingBtn}
                            rowData={switchData}
                            isActive={switchData?.isActive}
                            headerText={`Are you sure you want to ${switchData?.isActive ? "Inactive" : "Active"} this Dimension?`}
                        />
                    </div>{" "}
                    <NavigationComponent title={`Dimension Management`} addNewHandleDialog={handleDialog} isBackButtonLink={() => history.goBack()} />
                    <div className="card-ml-1 dark-bg">
                        <DataTable
                            header={
                                <div className="flex justify-content-end custom-alignment" style={{ display: "flex", alignItems: "center" }}>
                                    <span className="mr-2 font-semibold text-base">Status:</span>
                                    <Dropdown
                                        className=""
                                        style={{ width: "215px" }}
                                        options={statusOptions}
                                        optionLabel="name"
                                        optionValue="id"
                                        value={statusValue}
                                        onChange={(e) => setStatusValue(e.target.value)}
                                        placeholder="Select Status"
                                        itemTemplate={optionTemplate}
                                        valueTemplate={selectedItemTemplate}
                                    />{" "}
                                    <span className="p-input-icon-right">
                                        <i className="pi pi-search" />
                                        <InputText className="Input__Round ml-3" placeholder="Search..." value={globalFilterValue} onChange={onGlobalFilterChange} />
                                    </span>
                                </div>
                            }
                            filters={filters}
                            globalFilterFields={["dimension"]}
                            responsive={true}
                            responsiveLayout="scroll"
                            stripedRows
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            value={filteredDimensions}
                            emptyMessage="No records found"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        >
                            <Column field="dimension" header="Dimension" style={{ width: "150px" }} />
                            <Column body={createdDateAndTimeBodyTemplate} header="Created On" style={{ width: "150px" }} />
                            <Column header="Action" body={actionTemplate} style={{ width: "150px" }} />
                        </DataTable>
                        <Modal />
                    </div>
                </>
            )}
        </>
    );
};

export default ManageDimension;
