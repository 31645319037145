/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import * as Yup from "yup";
import classNames from "classnames";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../services/GetTemplate";
import { handlePatchRequest } from "../../services/PatchTemplate";
import { Dialog } from "primereact/dialog";
import Avatar from "react-avatar-edit";
import { baseURL } from "../../Config";
import Loading from "../../components/Loader";
import profilepng from "../../assets/png/dummyprofilepicture.png";

const EditViewUser = ({ onHide, getUserList, userdataId, isEditable }) => {
    const [allRoles, setAllRoles] = useState([]);
    const [previousImage, setPreviousImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [logo, setLogo] = useState(null);
    const [imageCrop, setImageCrop] = useState(false);
    const [profile, setProfile] = useState([]);
    const [pview, setPview] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const profileFinal = profile.map((item) => item.pview);
    const dispatch = useDispatch();
    const userinfo = JSON.parse(localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails"));

    const getUserById = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest(`/api/v1/users/getById/${userdataId}`, false));
        if (response?.status === 200) {
            const keyData = response?.data[0];
            formik.setFieldValue("name", keyData?.name);
            formik.setFieldValue("phone", keyData?.phone);
            formik.setFieldValue("email", keyData?.email);
            formik.setFieldValue("role", keyData?.role?.toString());
            setPreviousImage(keyData?.profileImage ? baseURL + "/" + keyData?.profileImage : profilepng);
        }
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    useEffect(() => {
        getAllRoles();
    }, []);

    useEffect(() => {
        getUserById();
    }, [userdataId]);

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .required("This field is required")
            .matches(/^[a-zA-Z0-9\s]*$/, "Name must be alpha numeric"),
        phone: Yup.string()
            .matches(/^\d{9,11}$/, "Field must be between 9 and 11 digits")
            .required("This field is required"),

        role: Yup.string().required("This field is required"),
        email: Yup.string().required("This field is required").email("Invalid email format"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            phone: "",
            role: "",
            email: "",
        },

        onSubmit: async (data) => {
            setLoadingBtn(true);

            let newData = {
                userId: userdataId,
                name: data?.name,
                phone: data?.phone,
                role: data?.role,
                email: data?.email,
                profileImage: logo ? logo : "",
            };

            const res = await dispatch(handlePatchRequest(newData, `/api/v1/users/update`, false, true));
            if (res.status === 200) {
                getUserList();
                onHide();
            }
            setLoadingBtn(false);
        },
    });

    const getAllRoles = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/roles/getAll", false));
        if (response) {
            const filteredRoles = response?.data?.filter((item) => {
                if (userinfo?.data?.role.toLowerCase() === "admin") {
                    return item?.name.toLowerCase() !== "super admin";
                }
                return true;
            });
            setAllRoles(filteredRoles);
        }
    };

    const onClose = () => {
        setPview(null);
    };
    const onCrop = (view) => {
        setPview(view);
    };
    const saveCropImage = () => {
        setProfile([...profile, { pview }]);
        setImageCrop(false);
        setLogo(pview);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            {loading ? <Loading /> : <></>}
            <form className="p-fluid p-mt-2" onSubmit={formik.handleSubmit}>
                <div className="text-center mb-2">
                    <div className="flex flex-column justify-content-center align-items-center">
                        <img style={{ width: "150px", height: "150px", borderRadius: "50%", objectFit: "cover", border: "4px solid black" }} src={profileFinal.length ? profileFinal : previousImage} alt="" />
                        {isEditable ? <Button onClick={() => setImageCrop(true)} type="button" label="Upload Image" className="Save-Button-Profile mt-3" /> : ""}
                        <Dialog visible={imageCrop} onHide={() => setImageCrop(false)}>
                            <div>
                                <Avatar width={500} height={400} onCrop={onCrop} onClose={onClose} shadingColor={"#474649"} backgroundColor={"474649"} />
                                <div className="flex flex-column align-items-center w-12 mt-5">
                                    <div className="flex justify-content-around w-12 mt-4">
                                        <Button onClick={saveCropImage} label="Save" icon="pi pi-check" type="button" className="Save-Button" />
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="p-field col-12 md:col-6">
                        <div className="field mt-3">
                            <label>Name</label> <span className="Label__Required">*</span>
                            <InputText id="name" placeholder="Enter Name" name="name" value={formik?.values?.name} maxLength={30} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__Round")} disabled={!isEditable} />
                            {getFormErrorMessage("name")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6">
                        <div className="field mt-3">
                            <label>Phone No</label> <span className="Label__Required">*</span>
                            <InputText keyfilter="int" id="phone" placeholder="Enter Phone No" name="phone" value={formik?.values?.phone} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("phone") }, "Input__Round")} disabled={!isEditable} />
                            {getFormErrorMessage("phone")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6">
                        <div className="field mt-4">
                            <label htmlFor="role" className={classNames({ "p-error": isFormFieldValid("role") }, "Label__Text")}>
                                Role
                            </label>{" "}
                            <span className="Label__Required">*</span>
                            <Dropdown
                                id="role"
                                placeholder="Select Role"
                                options={allRoles}
                                optionLabel="name"
                                name="role"
                                optionValue="name"
                                value={formik.values.role || ""}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": isFormFieldValid("role") }, "Dropdown__Round")}
                                disabled={!isEditable}
                            />
                            {getFormErrorMessage("role")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6">
                        <div className="field mt-4">
                            <label htmlFor="email" className="Label__Text">
                                Email
                            </label>{" "}
                            <span className="Label__Required">*</span>
                            <InputText
                                keyfilter={/[^\s]/}
                                className={classNames({ "p-invalid": isFormFieldValid("email") }, "Input__Round")}
                                placeholder="Enter Email"
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                type="text"
                                disabled={!isEditable || !userinfo?.data?.role?.toLowerCase().includes("super")}
                            />
                            {getFormErrorMessage("email")}
                        </div>
                    </div>

                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        {isEditable === true && <Button className="Save-Button ml-2" label="Update" type="submit" loading={loadingBtn} />}
                    </div>
                </div>
            </form>
        </>
    );
};

export default EditViewUser;
