import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { handlePatchRequest } from "../../services/PatchTemplate";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import { useDispatch } from "react-redux";
import { classNames } from "primereact/utils";
import { handleGetRequest } from "../../services/GetTemplate";
import Loading from "../../components/Loader";

const EditRequest = ({ onHide, requestdataId, getRequestList }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const getRequestById = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest(`/api/v1/requests/getById/${requestdataId}`, false));
        if (response?.status === 200) {
            const keyData = response?.data;
            formik.setFieldValue("isApproved", keyData?.isApproved === true ? "Approved" : keyData?.isApproved === false ? "Rejected" : "");
        }
        setLoading(false);
    };

    useEffect(() => {
        getRequestById();
    }, [requestdataId]);

    const validationSchema = Yup.object().shape({
        isApproved: Yup.string().required("This field is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            isApproved: "",
        },

        onSubmit: async () => {
            setLoading(true);
            let data = {
                isApproved: formik.values.isApproved === "Approved" ? true : false,
            };
            const res = await dispatch(handlePatchRequest(data, `/api/v1/requests/update/${requestdataId}`, false, true));
            if (res.status == 200) {
                setLoading(false);
                getRequestList();
                onHide();
            }
        },
    });

    const request = [
        { name: "Approved", value: "true" },
        { name: "Rejected", value: "false" },
    ];

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            {loading ? <Loading /> : <></>}
            <form className="p-fluid  pl-5 pr-5" onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-5 pr-5">
                    <div className="p-field col-12 md:col-2"></div>
                    <div className="p-field col-12 md:col-8">
                        <div className="field ">
                            <label htmlFor="isApproved" className={classNames({ "p-error": isFormFieldValid("isApproved") }, "Label__Text")}>
                                Status
                            </label>
                            <span className="Label__Required">*</span>
                            <Dropdown
                                id="isApproved"
                                options={request}
                                optionLabel="name"
                                name="isApproved"
                                optionValue="name"
                                value={formik.values.isApproved || ""}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": isFormFieldValid("isApproved") }, "Dropdown__Round")}
                                placeholder="Select Status"
                            />
                            {getFormErrorMessage("isApproved")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-2"></div>

                    <div className="col-12 d-flex flex-row text-center mt-5 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        <Button className="Save-Button ml-2" label="Update" type="submit" />
                    </div>
                </div>
            </form>
        </>
    );
};

export default EditRequest;
