import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../../services/GetTemplate";
import { handlePostRequest } from "../../../services/PostTemplate";
import SecuredFileChange from "../../../components/SecuredFileChange/SecuredFileChange";

const AddCars = ({ onHide, getCarList }) => {
    const [maker, setMaker] = useState([]);
    const [variant, setVariant] = useState([]);
    const [selectedFileError, setSelectedFileError] = useState(null);
    const [selectedFileArray, setSelectedFileArray] = useState([]);
    const carImageRef = useRef(null);

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        makerId: Yup.string()?.required("This field is required"),
        variantId: Yup.string()?.required("This field is required"),
        cost: Yup.string()?.required("This field is required"),
        quantity: Yup.string()?.required("This field is required"),
        images: Yup.string().when([], {
            is: () => selectedFileArray.length === 0,
            then: Yup.string().required("You must upload at least one image"),
            otherwise: Yup.string(),
        }),
        feature: Yup.string().required("This field is required").min(3, "field must be at least 3 characters long").max(50, "field cannot exceed 50 characters"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            makerId: "",
            variantId: "",
            feature: "",
            cost: "",
            quantity: "",
            images: "",
        },

        onSubmit: async () => {
            let data = {
                makerId: formik.values.makerId,
                variantId: formik.values.variantId,
                feature: formik.values.feature,
                cost: formik.values.cost,
                quantity: formik.values.quantity,
                images: selectedFileArray ? selectedFileArray : null,
            };
            const response = await dispatch(handlePostRequest(data, `/api/v1/vehicles/create`, false, true));
            if (response?.status === 200) {
                getCarList();
                onHide();
            }
        },
    });

    const getMaker = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/makers/getAll", false));
        if (response) {
            setMaker(response?.data);
        }
    };

    const getvariant = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/variant/getAll", false));
        if (response) {
            setVariant(response?.data);
        }
    };

    useEffect(() => {
        getMaker();
        getvariant();
    }, []);

    const handleDeleteImage = (index) => {
        setSelectedFileArray((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const handleCarIcon = () => {
        carImageRef.current.click();
    };

    // for multiple image upload
    function handleCarImageChange(event) {
        setSelectedFileError("");
        if (selectedFileArray?.length >= 6) {
            setSelectedFileError("You can only upload a maximum of 6 images.");
            return;
        }
        const selectedFile = event.target.files[0];
        const fileTypes = ["image"];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setSelectedFileError(error);
        } else if (newFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedFileArray((prevItems) => [...prevItems, base64String]);
            };
            reader.readAsDataURL(newFile);
        }
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="field col-12 md:col-6">
                        <label> Maker </label>
                        <span className="Label__Required">*</span>

                        <Dropdown className={classNames({ "p-invalid": isFormFieldValid("makerId") }, "Dropdown__Round")} id="makerId" name="makerId" options={maker} optionValue="id" optionLabel="name" value={formik.values.makerId} onChange={formik.handleChange} placeholder="Select Maker" />
                        {getFormErrorMessage("makerId")}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label> Variant </label>
                        <span className="Label__Required">*</span>
                        <Dropdown
                            className={classNames({ "p-invalid": isFormFieldValid("variantId") }, "Dropdown__Round")}
                            id="variantId"
                            name="variantId"
                            options={variant}
                            optionValue="variantId"
                            optionLabel="variantName"
                            value={formik.values.variantId}
                            onChange={formik.handleChange}
                            placeholder="Select Variant"
                        />
                        {getFormErrorMessage("variantId")}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label> Feature </label>
                        <span className="Label__Required">*</span>
                        <InputText className={classNames({ "p-invalid": isFormFieldValid("feature") }, "Input__Round")} id="feature" name="feature" type="text" value={formik.values.feature} onChange={formik.handleChange} placeholder="Enter Features" />
                        {getFormErrorMessage("feature")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Cost </label>
                        <span className="Label__Required">*</span>
                        <InputText className={classNames({ "p-invalid": isFormFieldValid("cost") }, "Input__Round")} id="cost" name="cost" type="text" value={formik.values.cost} onChange={formik.handleChange} placeholder="Enter Cost" />
                        {getFormErrorMessage("cost")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Quantity </label>
                        <span className="Label__Required">*</span>
                        <InputText className={classNames({ "p-invalid": isFormFieldValid("quantity") }, "Input__Round")} id="quantity" name="quantity" type="text" value={formik.values.quantity} onChange={formik.handleChange} placeholder="Enter Quantity" />
                        {getFormErrorMessage("quantity")}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label> Upload Image </label> <span className="Label__Required">*</span>
                        <div className="file-input-container " onClick={handleCarIcon}>
                            <input type="file" accept="image/*" style={{ display: "none" }} ref={carImageRef} onChange={handleCarImageChange} />
                            <input className={classNames({ "p-invalid": isFormFieldValid("images") }, "custom-file p-inputtext p-component Input__Round")} type="text" placeholder="Choose a file..." value={formik.values.images} readOnly />
                            <span className="file-upload-icon">
                                <i className="pi pi-paperclip" />
                            </span>
                        </div>
                        {selectedFileError ? <small className="p-error">{selectedFileError}</small> : getFormErrorMessage("images")}
                    </div>
                    <div className="field col-12 md:col-12">
                        <div>
                            <label> Uploaded Images </label>
                        </div>
                        <div className=" border-solid border-900 border-2 border-round p-5 mt-2">
                            {selectedFileArray.map((item, index) => {
                                return (
                                    <div key={index} style={{ position: "relative", display: "inline-block", margin: "5px", width: "110px", height: "110px" }}>
                                        <i className="pi pi-times closingIcon" onClick={() => handleDeleteImage(index)} style={{ cursor: "pointer", position: "absolute", top: "0", right: "0" }} />
                                        <img crossOrigin="anonymous" src={item} style={{ width: "90px", height: "90px", objectFit: "contain" }} alt="Uploaded file preview" />
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        <Button className="Save-Button ml-2" label="Add" type="submit" />
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddCars;
