import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import Modal from "../../components/modal/Modal";
import { handleGetRequest } from "../../services/GetTemplate";
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import editIcon from "../../../src/assets/png/edit.png";
import deleteIcon from "../../../src/assets/png/delete.png";
import viewIcon from "../../../src/assets/png/view.png";
import { baseURL } from "../../Config";
import Loading from "../../components/Loader";
import AddCars from "./Components/AddCars";
import EditViewCars from "./Components/EditViewCars";
import { InputSwitch } from "primereact/inputswitch";
import { handlePatchRequest } from "../../services/PatchTemplate";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

const ManageCar = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allCar, setAllCar] = useState([]);
    const [carData, setCarData] = useState([]);
    const [currentModal, setCurrentModal] = useState(null);
    const [isEditable, setIsEditAble] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cardataId, setCarDataId] = useState();
    const [displayDialog, setDisplayDialog] = useState(false);

    const statuses = ["Active", "Inactive"];

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        vehicleCategoryName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        updatedStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ""));
    };

    const dispatch = useDispatch();

    const statusField = (rowData) => {
        const statusName = rowData?.isActive ? "Active" : "Inactive";
        const statusStyle = {
            color: rowData?.isActive ? "green" : "red",
            fontWeight: "bold",
        };

        return <span style={statusStyle}>{statusName}</span>;
    };
    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
    };
    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                <InputSwitch className="edit-btn ml-3 mt-2" checked={rowData.isActive == "1"} onChange={() => handleInputSwitch(rowData)} />

                <Button className="edit-btn ml-5" onClick={() => handleEditCars(rowData)}>
                    <img src={editIcon} alt="Edit" />
                </Button>
                <Button className="edit-btn ml-3" onClick={() => handleViewCars(rowData)}>
                    <img src={viewIcon} alt="Edit" />
                </Button>
                <Button
                    className="edit-btn ml-3"
                    onClick={() => {
                        handleOpenModal();
                        setCarData(rowData);
                    }}
                >
                    <img src={deleteIcon} alt="Delete" />
                </Button>
            </div>
        );
    };

    const handleInputSwitch = async (rowData) => {
        // setLoading(true);
        const apiImages = rowData?.images?.map((item) => {
            return item?.img_path;
        });
        let obj = {
            id: rowData?.id,
            oldImages: apiImages,
            isActive: rowData?.isActive ? false : true,
        };

        const response = await dispatch(handlePatchRequest(obj, `/api/v1/vehicles/update`, false, true));
        if (response?.status === 200) {
            // setLoading(false);

            getCarList();
        }
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const onHide = () => {
        setCurrentModal(null);
        setDisplayDialog(false);
    };

    const handleDialog = () => {
        setCurrentModal("Add");
        setDisplayDialog(true);
    };

    const handleEditCars = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setIsEditAble(true);
        setCarDataId(rowData.id);
    };

    const handleViewCars = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setIsEditAble(false);
        setCarDataId(rowData.id);
    };

    const getCarList = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest("/api/v1/vehicles/getAll", true));
        if (response) {
            const updatedResponse = response?.data?.vehicles?.map((item) => {
                return {
                    ...item,
                    updatedStatus: item.isActive ? "Active" : "Inactive",
                };
            });
            setAllCar(updatedResponse);
        }
        setLoading(false);
    };

    useEffect(() => {
        getCarList();
    }, []);

    const handleDeleteCars = async () => {
        const response = await dispatch(handleDeleteRequest(`/api/v1/vehicles/delete/${carData?.id}`, true, true));
        if (response?.status === 200) {
            getCarList();
        }
    };

    const ImageTemplate = (rowData) => {
        return (
            <React.Fragment>
                <img crossorigin="anonymous" src={baseURL + "/" + rowData?.images[0]?.img_path} alt="Vehicle Image" width="80px" height="60px" />
            </React.Fragment>
        );
    };

    const headerText = currentModal === "Add" ? "Add" : isEditable === true ? "Edit" : "View";
    const headerStyle = { borderBottom: "2px solid red" };
    const header = <span style={headerStyle}>{headerText}</span>;

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Dialog resizable={false} header={header} visible={displayDialog} blockScroll style={{ width: "40vw" }} onHide={() => onHide()} draggable={false}>
                        {currentModal === "Add" ? <AddCars onHide={onHide} getCarList={getCarList} /> : currentModal === "EditView" && <EditViewCars onHide={onHide} cardataId={cardataId} getCarList={getCarList} isEditable={isEditable} />}
                    </Dialog>

                    <div>
                        <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleDeleteCars} rowData={carData} headerText="Are you sure you want to delete this Car?" />
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5 m-0">
                        <div className="p-field col-12 md:col-6">
                            <div className="p-field">
                                <h2 style={{ fontWeight: "700", color: "black" }}>Car Management </h2>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-6">
                            <div className="p-field">
                                <div>
                                    <Button
                                        label="Add New"
                                        className="Btn__Add"
                                        icon="pi pi-plus-circle"
                                        iconPos="left"
                                        onClick={() => {
                                            handleDialog();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-ml-1 dark-bg">
                        <DataTable
                            header={
                                <div className="flex justify-content-end custom-alignment" style={{ display: "flex", alignItems: "center" }}>
                                    <InputText className="Input__Round" placeholder="Search..." value={globalFilterValue} onChange={onGlobalFilterChange} />
                                </div>
                            }
                            filters={filters}
                            globalFilterFields={["vehicleCategoryName", "feature", "makerName", "vehicleModelName", "vehicleYear", "vehicleTransmission", "variantName", "vehiclePowerType"]}
                            responsive={true}
                            responsiveLayout="scroll"
                            stripedRows
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            value={allCar}
                            emptyMessage="No records found"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        >
                            <Column body={ImageTemplate} header="Image" style={{ width: "150px" }} />
                            <Column field="vehicleCategoryName" header="Category" style={{ width: "150px" }} />
                            <Column field="feature" header="Feature" style={{ width: "150px" }} />
                            <Column field="makerName" header="Maker" style={{ width: "150px" }} />
                            <Column field="vehicleModelName" header="Vehicle Model" style={{ width: "150px" }} />
                            <Column field="vehicleYear" header="Vehicle Year" style={{ width: "150px" }} />
                            <Column field="vehicleTransmission" header="Transmission" style={{ width: "150px" }} />
                            <Column field="variantName" header="Variant" style={{ width: "150px" }} />
                            <Column field="vehiclePowerType" header="Power Type" style={{ width: "150px" }} />
                            <Column field="updatedStatus" body={statusField} header="Status" style={{ width: "150px" }} filter filterElement={statusFilterTemplate} showFilterMatchModes={false} />
                            <Column body={actionTemplate} header="Action" style={{ width: "150px" }} />
                        </DataTable>
                        <Modal />
                    </div>
                </>
            )}
        </>
    );
};

export default ManageCar;
